/* This example requires Tailwind CSS v2.0+ */
// import axios from 'axios';
import { Fragment, useRef } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  AnnotationIcon,
  ChatAlt2Icon,
  InboxIcon,
  MenuIcon,
  QuestionMarkCircleIcon,
  XIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import React, { useState, useEffect } from 'react';
import {getUser} from '../utils/storage';
import { useJsApiLoader,useLoadScript,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer } from "@react-google-maps/api";
import Post from '../components/post/Post';
import { axiosInstance } from '../utils/axios';
import hotelgramerhome from '../assets/images/hotelgramerhome-2.jpeg'
import { useTranslation } from "react-i18next";


export default function Home() {
  const[tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const[tagInput, setTagInput] = useState('');
  const[lat, setLat] = useState('');
  const[long, setLong] = useState('');

  const[openDrowdown, setOpenDropdown] = useState(false);
  const locationRef = useRef()
  const userLogged = getUser();
  console.log(userLogged);
  const[posts, setPosts] = useState([]);

  const { t, i18n } = useTranslation();

  
  useEffect(() => {

    getLatestPosts()
  //   if (window.navigator.geolocation) {
  //     // Geolocation available
  //     window.navigator.geolocation
  // .getCurrentPosition(console.log, console.log);
  //    } 
  },[])


  function onPlaceChanged(){
    GetLatlong(locationRef.current.value);
  }

  function GetLatlong(address) {
    var geocoder = new google.maps.Geocoder();
  
    geocoder.geocode({
      'address': address
    }, function(results, status) {
  
      if (status == google.maps.GeocoderStatus.OK) {
        setLat(results[0].geometry.location.lat());
        setLong(results[0].geometry.location.lng());
        
  
      }
    });
  }

  function getLatestPosts(){
    let logged_user_id = 0;
    if (userLogged !== null){
      logged_user_id = userLogged.id;
    }
    axiosInstance.get(window.REACT_APP_API_ENDPOINT+'/api/posts/latest/'+logged_user_id)
    .then(function (response) {
        setPosts(response.data);
    })
    .catch(function (error) {
        // handle error
        console.log(error);
    })
  }

  function onSearch(e){
    e.preventDefault();

    let apiTags = [];
    let apiLocation;
    let apiLat;
    let apiLong;
    let logged_user_id = 0;

    if(locationRef.current.value == ''){
      apiLocation = 'nolocation';
      setLat('noLat',()=>{
        apiLat = lat;
      });
      setLong('noLong',()=>{
        apiLong = long;
      });
    }
    else{
      apiLocation = locationRef.current.value;
      GetLatlong(apiLocation);
      apiLat = lat;
      apiLong = long;
    }

    

    if(selectedTags.length == 0){
      // selectedTags.push('notags');
      apiTags.push('notags')
    }
    else{
      apiTags = selectedTags;
    }

    if(selectedTags.length  == 0 && locationRef.current.value == ''){
      return;
    }
    if (userLogged !== null){
      logged_user_id = userLogged.id;
    }
    setPosts([])
      axiosInstance.get(window.REACT_APP_API_ENDPOINT+'/api/posts/'+logged_user_id+'/'+apiLocation+'/'+apiTags+'/'+apiLat+'/'+apiLong)
        .then(function (response) {
            setPosts(response.data);
              // let tagNames = [];
              // // setTags(response.data);
              // if(response.data.length>0){
              //   response.data.map((tag)=>
              //   // setTags([...tags, tag.name.en])
              //   // setTags(tag.name.en)
              //   tagNames.push(tag.name.en)
              //   )
    
              //   setTags(tagNames);
              //   setOpenDropdown(true);
              // }
            
    
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })

    }

  function getSearchTags(value){
    if(value != ""){
      setTags([]);
      axiosInstance.get(window.REACT_APP_API_ENDPOINT+'/api/tags/posts/'+value)
      .then(function (response) {
            let tagNames = [];
            // setTags(response.data);
            if(response.data.length>0){
              response.data.map((tag)=>
              // setTags([...tags, tag.name.en])
              // setTags(tag.name.en)
              tagNames.push(tag.name.en)
              )
  
              setTags(tagNames);
              setOpenDropdown(true);
            }
           
  
      })
      .catch(function (error) {
          // handle error
          console.log(error);
      })
    }
   
  }

  function onDropdownClick(e){
    e.preventDefault();
    setOpenDropdown(!openDrowdown);
  }

  function onTagClick(e){
    setSelectedTags([...selectedTags, e.target.value])
    setTags((tags.filter(item => item !== e.target.value)))
    setTagInput("");
    setOpenDropdown(false);
  }

  function onTagRemove(e){
    setTags([...tags, e.target.value])
    setSelectedTags((selectedTags.filter(item => item !== e.target.value)))
  }

  function onTagInputChange(e){
    setTagInput(e.target.value);
    // setTags([]);
    getSearchTags(e.target.value);
    
    // if (tags.some(item => item.startsWith(e.target.value))) {
    //   console.log("iteeeem", item);
    // }
  }

  function handleTagKeyDown(e){
    if(e.key === 'Enter' && tagInput != ""){
      if(tags && !tags.includes(e.target.value)){
        setSelectedTags([...selectedTags, tagInput])
        setTagInput("");
      }
    }
  }


  const { isLoaded } = useLoadScript({
    // googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    // googleMapsApiKey: 'AIzaSyCHtWbX8q4T_KTyTasNi9btO5fGoTZRuys',
    googleMapsApiKey: 'AIzaSyAvSF-WLyQRSnh4dE6UuOOqUsOTA1rmq3Y',
    libraries: ['places'],
  });

  const postList = posts.map((post) =>
      <Post post={post} type="home"/>
  );

  if (!isLoaded) return <div>Loading...</div>;

  else

  return (
    <div className="bg-white">
     

      <main>
        <div>
          {/* Hero card */}
          <div className="relative">
            <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
            <div className="max-w-12xl mx-auto">
              <div className="relative shadow-xl sm:overflow-hidden">
                <div className="absolute inset-0">
                  <img
                    className="h-full w-full object-cover"
                    src={hotelgramerhome}
                    alt="banner"
                  />
                </div>
                <div className="relative max-w-7xl mx-auto px-4 py-52 sm:px-6 lg:px-8">
                  <h1 className="text-left text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                    <span className="block text-white">{t('home.banner.title')}</span>
                    {/* <span className="block text-white">customer support</span> */}
                  </h1>
                  <p className="mt-6 max-w-lg text-left text-xl text-white sm:max-w-3xl">1<sup>st</sup>
                  {t('home.banner.subtitle')}

                  </p>
                  <div className="space-y-8 divide-y py-8 mt-2">
                    <div className="relative">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-5 items-end">
                        <div className="sm:col-span-2">
                          <label for="city" className="block text-sm font-medium text-white">
                              {t('home.search.location')}
                            </label> 
                            <div className="relative h-9">
                              <Autocomplete onPlaceChanged={onPlaceChanged}>
                                <input type="text" required="required" ref={locationRef} className="
                                mt-1
                                block
                                w-full
                                border border-gray-300
                                rounded-md
                                shadow-sm
                                py-2
                                px-3
                                focus:outline-none focus:ring-orange-500 focus:border-orange-500
                                sm:text-sm
                                "/>
                              </Autocomplete>
                              </div>
                              </div> 

                              <div className="sm:col-span-2">
                              <div className="w-full flex flex-col items-center mx-auto">
                      <div className="w-full">
                          <div className="flex flex-col items-center relative">
                              <div className="w-full">
                              <label for="tags" className="block text-sm font-medium text-white">
                              {t('home.search.tags')}
                            </label>
                                  <div className="p-1 flex border border-gray-200 bg-white rounded h-9">
                                      <div className="flex flex-auto flex-wrap">
                                        
                                          {selectedTags.map((tag)=>
                                                  <div className="flex justify-center items-center font-medium py-1 px-2 bg-white rounded-full text-teal-700 bg-teal-100 border border-teal-300 ">
                                                  <div className="text-xs font-normal leading-none max-w-full flex-initial">{tag}</div>
                                                  <div className="flex flex-auto flex-row-reverse">
                                                      <div className="relative">
                                                          <input className="absolute inset-0 w-4 h-4 ml-2 opacity-0 cursor-pointer border-gray-300 rounded-md" value={tag} onClick={onTagRemove}/>
                                                          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2">
                                                              <line x1="18" y1="6" x2="6" y2="18"></line>
                                                              <line x1="6" y1="6" x2="18" y2="18"></line>
                                                          </svg>
                                                      </div>
                                                  </div>
                      
                                              </div>
                                          )}
                                        
                                          <div className="flex-1">
                                              <input placeholder="" className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800" value={tagInput} onChange={onTagInputChange} onKeyDown={handleTagKeyDown}/>
                                          </div>
                                      </div>
                                      {/* <div class="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200 svelte-1l8159u" onClick={onDropdownClick}>
                                          <button class="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-chevron-up w-4 h-4">
                                                  <polyline points="18 15 12 9 6 15"></polyline>
                                              </svg>
                                          </button>
                                      </div> */}
                                  </div>
                              </div>
                              {openDrowdown && <div className="absolute shadow top-100 bg-white z-40 w-full lef-0 rounded max-h-select overflow-y-auto svelte-5uyqqj">
                                  <div className="flex flex-col w-full">
                                    {tags.map((tag)=>
                                      <div key={tag} className="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100">
                                        <div className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100">
                                            <div className="w-full items-center flex">
                                                <div className="mx-2 leading-6  ">{tag} </div>
                                                <input className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md" value={tag} onClick={onTagClick}/>
                                            </div>
                                        </div>
                                      </div>
                                    )}
                                
                                  </div>
                              </div>}
                          </div>
                      </div>
                  </div>

                              </div>

                     <div class="sm:col-span-1"><button type="submit" class="
    ml-3
    inline-flex
    justify-center
    py-2
    px-4
    border border-transparent
    shadow-sm
    text-sm
    font-medium
    rounded-md
    text-white
    bg-primaryColor
    focus:outline-none
    focus:ring-2
    focus:ring-offset-2
    focus:ring-orange-500
  " onClick={onSearch}>
  {t('home.search.title')}
</button></div></div></div></div>
                
                </div>
              </div>
            </div>
          </div>

       
        </div>

        {/* More main page content here... */}

        {postList && <ul role="list" className="w-full max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-3 mt-14"> 
                    {postList}
                </ul>}
          
      </main>
    </div>
  )
}
