import React from 'react'

export default function Input(props) {
  // const [value, setValue] = useState('');

  function handleInputChange(e){
    props.handleInputChange(e.target.value);
  }
  return (
    <div>
        <label className="block text-sm font-medium text-gray-700 text-left">
        {props.labelText}
        </label>
        <div className="mt-1">
        <input
            value={props.value}
            onChange={handleInputChange}
            type={props.type}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
        {props.errorMsg && <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
			    {props.errorMsg}
		    </span>}
        </div>
    </div>
  )
}
