import React, { useState, useEffect, createContext, useContext } from "react";
import { axiosInstance } from "../../utils/axios";
import { getUser } from "../../utils/storage";
import { useTranslation } from "react-i18next";

const userLogged = getUser();

export default function UserFollow(props) {
    const [followed, setFollowed] = useState(false);
    const [userFollow, setUserFollow] = useState(null);
    // const [userSearch, setUserSearch] = useState(props.user);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        getFollow();
        console.log("prooops", props.user)
        // setUserSearch(props.user);
    }, []);

    async function getFollow() {
        // axios.get('http://127.0.0.1:8000/api/users/1')
        //     .then(function (response) {
        //         console.log("response", response.data);
        //         setUser(response.data);
        //     })
        //     .catch(function (error) {
        //         // handle error
        //         console.log(error);
        //     })

        const res = await axiosInstance.get(
            window.REACT_APP_API_ENDPOINT +
                "/api/follows/checkFollow/" +
                props.user.id +
                "/" +
                userLogged.id
        );

        let data = await res.data;
        if (data.length > 0) {
            setFollowed(true);
            setUserFollow(data[0]);
        }
    }

    function onUserFollow() {
        if (followed == false) {
            const data = {
                user_id: props.user.id,
                follower_id: userLogged.id,
            };

            axiosInstance
                .post(window.REACT_APP_API_ENDPOINT + "/api/follows", data)
                .then((response) => {
                    console.log(response);
                
                    setFollowed(true);
                    setUserFollow(response.data.follow);
                    window.location.reload();
                    console.log('I am loading')
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response);
                        console.log("server responded");
                    } else if (error.request) {
                        console.log("network error");
                    } else {
                        console.log(error);
                    }
                });
        } else {
            axiosInstance
                .delete(
                    `${window.REACT_APP_API_ENDPOINT}/api/follows/${userFollow.id}`
                )
                .then((res) => {
                    console.log(res);
                    console.log(res.data);
                    setFollowed(false);
                    window.location.reload();
                    console.log('I am loading')
                    // const posts = postLikes.filter(item => item.id !== postLike.id);
                    // setPostLikes(posts);
                });
        }
    }

    return (
        <li key={props.user.id} className="flex items-center py-4 space-x-3">
            <div className="flex-shrink-0">
                <img
                    className="h-8 w-8 rounded-full object-cover"
                    src={
                        props.user.file
                            ? 
                              props.user.file
                            : "https://imgur.com/E0h3MGt.png"
                    }
                    alt=""
                />
            </div>
            <div className="min-w-0 flex-1">
                <p className="text-sm font-medium text-gray-900 text-left">
                    <a
                        href={
                            "/" +
                            i18n.language +
                            "/profile/" +
                            props.user.username
                        }
                    >
                        {props.user.username}
                       <p className="grayText"> {props.user.name} {props.user.last_name}</p>
                       <p className="grayText"> {props.user.mutualFollower}</p>
                    </a>
                </p>
                {/* <p className="text-sm text-gray-500">
            <a href={user.href}>{'@' + user.handle}</a>
        </p> */}
            </div>
            {props.user.username == userLogged.username ? (
                ""
            ) : (
                <div className="flex-shrink-0">
                    <button
                        onClick={onUserFollow}
                        type="button"
                        className="inline-flex items-center px-3 py-0.5 rounded-full bg-rose-50 text-sm font-medium text-rose-700 hover:bg-rose-100"
                    >
                        {/* <PlusSmIcon className="-ml-1 mr-0.5 h-5 w-5 text-rose-400" aria-hidden="true" /> */}
                        <span>
                            {followed ? t("user.unfollow") : t("user.follow")}
                        </span>
                    </button>
                </div>
            )}
        </li>
    );
}
