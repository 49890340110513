import React from 'react'

export default function Button(props) {
  return (
    <button
        onClick={props.handleClick}
        type="submit"
        className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primaryColor"
    >
        {props.text}
    </button>
  )
}
