import React, { useEffect, useState, Fragment } from 'react'
import axios from 'axios';
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { getUser } from '../../utils/storage'

export default function InstagramPost(props) {
    const [posts, setPosts] = useState([]);
    const [selectedPost, setSelectedPost] = useState();
    const [open, setOpen] = useState(true)

    useEffect(() => {
        getPosts();
    },[]);

    function createPost(data) {
        data.forEach(element => {
            axios.get('https://graph.instagram.com/'+element.id+'?fields=id,caption,media_type,media_url&access_token='+props.accessToken)
            .then(function (secondResponse) {
                // instaPosts.push(secondResponse.data);
                setPosts(posts => [...posts, secondResponse.data])
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
        });
    }


    function getPosts(){
        let instaPosts = [];

        axios.get('https://graph.instagram.com/me/media?fields=id&access_token='+props.accessToken)
        .then(function (response) {
            createPost(response.data.data);
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })

        // setPosts(instaPosts);

    }

    function onPostClick(post){
        setSelectedPost(post)
        props.instaPost(post);
        setOpen(false);
    }

    return (

        <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
            </span>
            
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => setOpen(false)}
                >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                </div>
                <div>
                
                <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {/* {props.type = "followers" && "Followers"} */}
                    {/* {props.type = "following"? "Following": ""} */}
                    </Dialog.Title>
                    <div className="mt-2">
                    <div class="grid grid-cols-4 gap-4">
                        {posts.map((post)=>
                            <div onClick={() => onPostClick(post)}>
                                <img src={post.media_url}/>
                            </div>
                        )}
            </div>

                    </div>
                </div>
                </div>
            
            </div>
            </Transition.Child>
        </div>
        </Dialog>
        </Transition.Root>
    )
}