import React from 'react'
import { useTranslation } from 'react-i18next';

export default function PrivacyPolicy() {
  const { i18n } = useTranslation();

  if(i18n.language == 'de'){
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
      <div className="py-4 sm:py-5">
      <h1 className="text-4xl font-bold text-gray-500 mb-2 text-bold">Allgemeine Gesch&auml;fts- &amp; Mietbedingungen der Travelgram GmbH</h1>
      <br/>
      <p className="mt-1 text-lg text-gray-500"><strong>1. &nbsp;Verwendete Begriffe</strong></p>
      <p className="mt-1 text-lg text-gray-500"><strong>AGB:&nbsp;</strong>Allgemeine Gesch&auml;ftsbedingungen der Travelgram GmbH</p>
      <p className="mt-1 text-lg text-gray-500"><strong>Influencer:&nbsp;</strong>Umfassen Social Media Posts (JPEG, PNG, JPEG, GIF, VIDEO Dateien) inklusive Hashtags, Profi, Netzwerk, Standortinformationen und Kommentare, welche zu Promotion zwecken &uuml;ber die Webseite von Travelgram geteilt werden</p>
      <p className="mt-1 text-lg text-gray-500"><strong>Reisender:&nbsp;</strong>Person, welche &uuml;ber einen Social Media Post &uuml;ber den Influencer oder dem Hoteleigenen Social Media Post &uuml;ber die Webseite von Travelgram bucht&nbsp;</p>
      <p className="mt-1 text-lg text-gray-500"><strong>Nutzer:&nbsp;</strong>Umfasst sowohl den Influencer wie auch den Reisenden</p>
      <p className="mt-1 text-lg text-gray-500"><strong>Hotel:&nbsp;</strong>Anbieter von Hotelzimmern, die &uuml;ber die Webseite von Travelgram zur Buchung angeboten werden</p>
      <p className="mt-1 text-lg text-gray-500">Aus Gr&uuml;nden der Lesbarkeit und Verst&auml;ndlichkeit wird die m&auml;nnliche Form verwendet, es werden aber alle Geschlechter damit angesprochen.&nbsp;</p>
      <p className="mt-1 text-lg text-gray-500"><strong>&nbsp;</strong></p>
      <p className="mt-1 text-lg text-gray-500"><strong>2. &nbsp;Allgemeines</strong></p>
      <ol>
          <li className="mt-1 text-lg text-gray-500">Die Travelgram GmbH mit Sitz in Zurich (nachfolgend &quot;<strong>Travelgram</strong>&quot; genannt) erbringt &uuml;ber die Webseite https://www.Travelgram.app (nachfolgend &quot;<strong>&nbsp;</strong><strong>Plattform</strong>&quot; genannt) Leistungen f&uuml;r Nutzer. Diese haben die M&ouml;glichkeit, &uuml;ber die Plattform Beherbergungsvertr&auml;ge abzuschliessen (nachfolgend &quot;<strong>Vertrag</strong>&quot; genannt).&nbsp;<strong>Travelgram selbst ist nicht Partei dieser Beherbergung Vertr&auml;ge.</strong></li>
          <li className="mt-1 text-lg text-gray-500">Folgende Dokumente bilden jedoch integrativen Bestandteil des Beherbergungsvertrages: (i) das &Uuml;bergabeprotokoll, (ii) diese AGB&rsquo;s, (iii) die Angaben im Userprofil, (iv).</li>
          <li className="mt-1 text-lg text-gray-500">Mit dem Anklicken des Feldes &quot;<em>Ich habe die AGB und Datenschutzbestimmungen gelesen und bin damit einverstanden</em>&quot; erkl&auml;rt sich der Nutzer mit diesen AGB und den Datenschutzbestimmung einverstanden und das Vertragsverh&auml;ltnis zwischen Travelgram und dem Nutzer kommt zustande (nachfolgend &quot;<strong>Vertragsverh&auml;ltnis</strong>&quot; genannt). Es besteht kein Anspruch des Nutzers auf Abschluss eines Vertragsverh&auml;ltnisses mit Travelgram. Bei Widerspr&uuml;chen zwischen verschiedenen Vertragsdokumenten von Travelgram und dem Nutzer haben die Dokumente in der nachfolgend aufgef&uuml;hrten Reihenfolge Vorrang: 1. individuell von beiden Parteien unterzeichnete Vertr&auml;ge; 2. diese AGB; 3. abweichende Gesch&auml;ftsbedingungen des Nutzers.&nbsp;</li>
          <li className="mt-1 text-lg text-gray-500">S&auml;mtliche Angaben, die von Travelgram ausserhalb des Vertragsverh&auml;ltnisses gemacht werden, namentlich auf der Plattform, sind unverbindlich freibleibend und als blosse Einladung zur Offerte zu verstehen, sofern dies durch Travelgram nicht ausdr&uuml;cklich anders festgelegt wird.</li>
      </ol>
      <p className="mt-1 text-lg text-gray-500">&nbsp;</p>
      <p className="mt-1 text-lg text-gray-500"><strong>3. &nbsp;Registrierung des Nutzers</strong></p>
      <ol>
          <li className="mt-1 text-lg text-gray-500">Um eine Promotion anzubieten, muss sich jeder Nutzer vor der vollst&auml;ndigen Inanspruchnahme der Leistungen &uuml;ber die Plattform registrieren.&nbsp;</li>
          <li className="mt-1 text-lg text-gray-500">F&uuml;r die Registrierung m&uuml;ssen alle Nutzer mind. folgende Kriterien erf&uuml;llen:&nbsp;</li>
          <li className="mt-1 text-lg text-gray-500">Handlungsf&auml;hige nat&uuml;rliche oder juristische Person sein. Bei juristischen Personen muss immer mindestens eine bevollm&auml;chtigte nat&uuml;rliche Person angegeben werden;</li>
          <li className="mt-1 text-lg text-gray-500">s&auml;mtliche allenfalls im Zusammenhang mit der Nutzung der Leistungen von Travelgram und/oder der Plattform stehenden Steuern, Geb&uuml;hren, und Abgaben selbst&auml;ndig zu deklarieren und zu entrichten; und&nbsp;</li>
          <li className="mt-1 text-lg text-gray-500">die Registrierung von Ehepaaren, Familien oder Freunden ist m&ouml;glich. Diese m&uuml;ssen jedoch immer eine nat&uuml;rliche Person als gemeinsamen Vertreter (Ansprechperson) angeben, welcher als Vertragspartei auftritt.&nbsp;</li>
          <li className="mt-1 text-lg text-gray-500">Dar&uuml;ber hinaus m&uuml;ssen die Influencer folgende Voraussetzungen erf&uuml;llen:&nbsp;</li>
          <li className="mt-1 text-lg text-gray-500">Vollj&auml;hrigkeit erreicht haben.&nbsp;</li>
          <li className="mt-1 text-lg text-gray-500">zahlungsf&auml;hig sein, d.h. in der Lage sein, die anfallenden Kosten des Beherbergungsvertrages vollumf&auml;nglich zu tragen;</li>
          <li className="mt-1 text-lg text-gray-500">Inhabers eines Bankkontos einer in der Schweiz zugelassenen Bank oder eines anderen Zahlungsmittels wie z.B. einer Kreditkarte, TWINT etc. sein, welches&nbsp;allf&auml;llige R&uuml;ckerstattungen erlaubt; und</li>
          <li className="mt-1 text-lg text-gray-500">Der Nutzer sichert Travelgram zu, dass alle von ihm bei der Registrierung angegebenen Daten wahr, die geteilten Inhalte sich im Eigentum des Nutzers befinden und die angegebenen Daten vollst&auml;ndig sind und verpflichtet sich allf&auml;llige &Auml;nderungen unverz&uuml;glich schriftlich an Travelgram mitzuteilen.&nbsp;</li>
          <li className="mt-1 text-lg text-gray-500">Der Nutzer darf sich nur einmal registrieren. Er ist damit einverstanden, dass s&auml;mtliche mit seinen Zugangsdaten auf der Plattform get&auml;tigten Aktivit&auml;ten ihm zugerechnet werden, als w&auml;ren es seine eigenen. Wenn die Zugangsdaten des Nutzers durch Dritte missbraucht werden, haftet der Nutzer wie f&uuml;r seine eigenen Handlungen.&nbsp;</li>
      </ol>
      <p className="mt-1 text-lg text-gray-500">&nbsp;</p>
      <p className="mt-1 text-lg text-gray-500"><strong>4. &nbsp;Plattform</strong></p>
      <ol>
          <li className="mt-1 text-lg text-gray-500">Travelgram bietet den Nutzern &uuml;ber die Plattform die M&ouml;glichkeit, miteinander zu kommunizieren und mit Hotels Beherbergungsvertr&auml;ge abzuschliessen. Travelgram bietet grunds&auml;tzlich keine eigenen Unterk&uuml;nfte an, sondern vermittelt lediglich Vertragsschl&uuml;sse zwischen Nutzern und Anbietern. Zu Test-/Werbezwecken kann Travelgram ebenfalls Promotions auf der Plattform aufschalten.</li>
          <li className="mt-1 text-lg text-gray-500">Der Nutzer gew&auml;hrt Travelgram hiermit ein unentgeltliches, nicht widerrufbares, sachlich, &ouml;rtlich und zeitlich unbeschr&auml;nktes, sowie unterlizenzierbares Nutzungsrecht an den vom Nutzer auf der Plattform eingestellten Inhalten (nachfolgend &quot;<strong>Nutzer-Inhalte</strong>&quot; genannt). Vom Begriff &ldquo;Nutzer-Inhalte&rdquo; im Sinne dieser Ziff. 2 sind Profilbilder des Nutzers ausgeschlossen. Travelgram darf die Nutzer-Inhalte - selbst in ver&auml;nderter Form - insbesondere auch im Rahmen von Marketing- oder Promotionszwecken verwenden. Das Nutzungsrecht besteht &uuml;ber die Beendigung des Vertragsverh&auml;ltnisses f&uuml;r unbestimmte Zeit fort.</li>
          <li className="mt-1 text-lg text-gray-500">Der Nutzer gew&auml;hrleistet gegen&uuml;ber Travelgram, dass (i) er alle Rechte an den Nutzer-Inhalten besitzt - im Sinne dieser Ziff. 3 erfasst der Begriff &ldquo;Nutzer-Inhalte&rdquo; auch die Profilbilder des Nutzers, (ii) er, wenn die Nutzer-Inhalte Eigentumsrechten Dritter unterliegen, &uuml;ber alle notwendigen Lizenzen, Rechte und Zustimmungen verf&uuml;gt, um die Nutzer-Inhalte zu ver&ouml;ffentlichen, (iii) die Nutzer-Inhalte nicht vertraulich, beleidigend, diffamierend, obsz&ouml;n, pornographisch, unanst&auml;ndig, bedrohend, oder bel&auml;stigend sind, (iv) die Nutzer-Inhalte nicht geeignet sind, die Interessen von Travelgram zu beeintr&auml;chtigen oder den Ruf von Travelgram zu sch&auml;digen, und (v) er die anwendbaren Gesetze vollst&auml;ndig einh&auml;lt. &nbsp;&nbsp;</li>
          <li className="mt-1 text-lg text-gray-500">Travelgram ist berechtigt, das Vertragsverh&auml;ltnis mit einem Nutzer jederzeit (ohne Grundangabe) zu beenden sowie die beschriebenen Leistungen unter Beachtung der nachstehenden Ziff. 13 jederzeit zu &auml;ndern oder einzustellen. Zudem kann Travelgram Promotions jederzeit ohne Begr&uuml;ndung ablehnen, &auml;ndern oder entsprechende Eintr&auml;ge jederzeit l&ouml;schen.&nbsp;</li>
      </ol>
      <p className="mt-1 text-lg text-gray-500">&nbsp;</p>
      <p className="mt-1 text-lg text-gray-500"><strong>5. &nbsp;Beherbergungsvertrag</strong></p>
      <p className="mt-1 text-lg text-gray-500">5.1 Abschluss des Beherbergungsvertrages; Buchungsprozess</p>
      <ol>
          <li className="mt-1 text-lg text-gray-500">Unterkunft Anbieter (Hotels) wie auch Nutzer haben keinen Anspruch auf Abschluss eines Beherbergungsvertrages.</li>
          <li className="mt-1 text-lg text-gray-500"><strong>Die Rechte und Pflichten aus den Beherbergungsvertr&auml;gen entstehen ausschliesslich zwischen den Nutzern und Hotels. F&uuml;r die Erf&uuml;llung der Pflichten aus den Beherbergungsvertr&auml;gen sind ausschliesslich der Nutzer und Hotels verantwortlich. S&auml;mtliche Verantwortung und Haftung von Travelgram in diesem Zusammenhang ist explizit und umfassend ausgeschlossen.</strong></li>
          <li className="mt-1 text-lg text-gray-500">In jedem Fall ist jede get&auml;tigte Buchung auf der Plattform jeweils mit dem Buchungsabschluss f&uuml;r den user wie auch das Hotel verbindlich (vgl. Ziff. 7 hiernach betreffend Vertragsanpassung, R&uuml;cktritt und Vertragsaufhebung).&nbsp;</li>
          <li className="mt-1 text-lg text-gray-500">Nach Buchungsabschluss hat der Nutzer, den f&auml;lligen Beherbergungsvertrag direkt online zu bezahlen.&nbsp;</li>
          <li className="mt-1 text-lg text-gray-500">Nutzer und Hotel werden sobald der vollst&auml;ndige Beherbergungsbetrag bei Travelgram eingegangen ist, &uuml;ber den Abschluss des Beherbergungsvertrages durch ein Best&auml;tigungsmail orientiert. &nbsp;&nbsp;</li>
      </ol>
      <p className="mt-1 text-lg text-gray-500"><br/></p>
      <p className="mt-1 text-lg text-gray-500"><br/></p>
      <p className="mt-1 text-lg text-gray-500"><strong>6. &nbsp;Zahlungsablauf</strong></p>
      <ol>
          <li className="mt-1 text-lg text-gray-500">Der Beherbergungsvertrag kommt immer &uuml;ber den auf der Plattform aufgef&uuml;hrten Hotelbetrag zustande. Diese Vorschrift kann von den Parteien des Beherbergungsvertrages nicht abge&auml;ndert werden. Der Betrag beinhaltet den Anbieteranteil und die Travelgram Servicegeb&uuml;hr.</li>
          <li className="mt-1 text-lg text-gray-500">Der Betrag versteht sich immer inklusive Mehrwertsteuer, sofern diese geschuldet ist und auf der Plattform nichts anderes festgelegt wird.</li>
          <li className="mt-1 text-lg text-gray-500">Der zu zahlende Betrag, sowie die Zahlungsm&ouml;glichkeiten und weitere Zahlungsmodalit&auml;ten werden auf der Plattform geregelt.&nbsp;</li>
          <li className="mt-1 text-lg text-gray-500">Der Nutzer ist verpflichtet, daf&uuml;r zu sorgen, dass die Kreditkarte sowie andere Zahlungsmittel, mit welchen er allf&auml;llige Zahlungen gem&auml;ss diesen AGB t&auml;tigt, g&uuml;ltig sind und &uuml;ber die erforderliche Deckung verf&uuml;gen.</li>
          <li className="mt-1 text-lg text-gray-500">Der Nutzer hat den Mietbetrag, in der auf der Plattform dargestellten Weise, rechtzeitig an Travelgram zu bezahlen.&nbsp;</li>
      </ol>
      <p className="mt-1 text-lg text-gray-500"><br/></p>
      <p className="mt-1 text-lg text-gray-500"><strong>7. &nbsp;Vertragsanpassung, R&uuml;cktritt &amp; Vertragsaufhebung</strong></p>
      <ol>
          <li className="mt-1 text-lg text-gray-500"><strong>Eine get&auml;tigte Buchung auf der Plattform ist mit dem Buchungsabschluss verbindlich.&nbsp;</strong>W&uuml;nscht ein Nutzer danach &Auml;nderungen der Buchung oder eine Stornierung der Buchung, so kann er dies direkt &uuml;ber die Plattform anfragen. Das Hotel ist nicht verpflichtet den &Auml;nderungsw&uuml;nschen zuzustimmen. Diese werden nur dann bearbeitet, wenn diese von beiden Nutzern best&auml;tigt wurden. Die Nutzer sind verpflichtet, Travelgram umgehend &uuml;ber alle &Auml;nderungen zu informieren oder die &Auml;nderungen direkt &uuml;ber die Plattform vorzunehmen.&nbsp;&nbsp;</li>
      </ol>
      <p className="mt-1 text-lg text-gray-500"><strong>8. &nbsp;Social Media Inhalt</strong></p>
      <ol>
          <li className="mt-1 text-lg text-gray-500">Social Media Inhalte wie Bewertungen, Aussagen, Kommentare werden von Travelgram nicht &uuml;berpr&uuml;ft. Travelgram &uuml;bernimmt keine Gew&auml;hr f&uuml;r die Richtigkeit oder Angemessenheit der Bewertungen. Travelgram beh&auml;lt sich jedoch vor, Bewertungen oder Kommentare ohne Angabe von Gr&uuml;nden zu l&ouml;schen.&nbsp;</li>
      </ol>
      <p className="mt-1 text-lg text-gray-500">&nbsp;</p>
      <p className="mt-1 text-lg text-gray-500"><strong>9. &nbsp;Schutz der Urheber- und Markenrechte</strong></p>
      <p className="mt-1 text-lg text-gray-500">Der Nutzer nimmt zur Kenntnis, dass s&auml;mtliche Elemente auf der Travelgram Plattform, etwa Texte, Bilder oder H&ouml;rsignale, grunds&auml;tzlich Eigentum anderer Nutzer geh&ouml;ren und ausschliesslich zum privaten Gebrauch heruntergeladen werden d&uuml;rfen. Insbesondere d&uuml;rfen diese Elemente vom Nutzer nicht zu kommerziellen Zwecken verbreitet, kopiert, gesendet, ge&auml;ndert oder in anderer Weise genutzt werden.</p>
      <p className="mt-1 text-lg text-gray-500"><strong>&nbsp;</strong></p>
      <p className="mt-1 text-lg text-gray-500"><strong>10. &nbsp;Haftungsbeschr&auml;nkung und Gew&auml;hrleistungsausschluss</strong></p>
      <ol>
          <li className="mt-1 text-lg text-gray-500"><strong>Die Haftung von Travelgram gegen&uuml;ber dem Nutzer im Zusammenhang mit dem Vertragsverh&auml;ltnis, dem Beherbergungsvertrag oder der Plattform wird soweit gesetzlich zul&auml;ssig ausgeschlossen. Insbesondere haftet Travelgram nicht f&uuml;r Hilfspersonen. Zudem wird jegliche Haftung f&uuml;r mit dem Vertragsverh&auml;ltnis direkt oder indirekt zusammenh&auml;ngende Sch&auml;den ausgeschlossen, unabh&auml;ngig davon, aus welchem Rechtsgrund die Sch&auml;den geltend gemacht werden.</strong></li>
          <li className="mt-1 text-lg text-gray-500">Die Haftung von Travelgram gegen&uuml;ber dem Nutzer f&uuml;r alle Dienstleistungen, welche nicht von Travelgram selber, sondern von einem Vertragspartner von Travelgram ausgef&uuml;hrt werden, wird soweit gesetzlich zul&auml;ssig ausgeschlossen.&nbsp;</li>
          <li className="mt-1 text-lg text-gray-500">Travelgram ist bem&uuml;ht, die Plattform unter Ber&uuml;cksichtigung von Wartungsarbeiten und Down Time f&uuml;r Aktualisierungen durchg&auml;ngig zur Verf&uuml;gung zu stellen. Travelgram gew&auml;hrleistet jedoch nicht, dass die Leistungen jederzeit ohne Unterbrechung, zeitgerecht, sicher und fehlerfrei zur Verf&uuml;gung stehen.&nbsp;</li>
          <li className="mt-1 text-lg text-gray-500"><strong>Travelgram gibt gegen&uuml;ber dem Nutzer im Zusammenhang mit der Plattform, dem Beherbergungsvertrag und dem Vertragsverh&auml;ltnis keine Gew&auml;hrleistungen ab und schliesst die allenfalls bestehenden gesetzlichen Gew&auml;hrleistungen aus.</strong> Der Geltungsbereich dieses umfassend zu verstehenden Gew&auml;hrleistungsausschlusses wird durch die nachfolgenden spezifischen Bestimmungen nicht eingeschr&auml;nkt.</li>
          <li className="mt-1 text-lg text-gray-500">Die Plattform kann Links enthalten zu Webseiten von Dritten, auf deren Inhalte Travelgram keinen Einfluss hat. Auch f&uuml;r diese fremden Inhalte &uuml;bernimmt Travelgram keine Gew&auml;hr.</li>
      </ol>
      <p className="mt-1 text-lg text-gray-500">&nbsp;</p>
      <p className="mt-1 text-lg text-gray-500"><strong>11. &nbsp;Schadloshaltung</strong></p>
      <p className="mt-1 text-lg text-gray-500">Der Nutzer stellt Travelgram von s&auml;mtlichen Anspr&uuml;chen frei, die Dritte (inklusive andere Nutzer) gegen Travelgram geltend machen und die damit zusammenh&auml;ngen, dass der Nutzer gegen Bestimmungen des Vertragsverh&auml;ltnisses verstossen hat. Der Nutzer &uuml;bernimmt alle f&uuml;r Travelgram anfallende Kosten, die aufgrund einer solchen Verletzung von Bestimmungen des Vertragsverh&auml;ltnisses entstehenden, einschliesslich der f&uuml;r die Rechtsverteidigung (auch vorprozessual) entstehenden Kosten. Allf&auml;llige weitergehende Rechte sowie Schadensersatzanspr&uuml;che von Travelgram bleiben unber&uuml;hrt.</p>
      <p className="mt-1 text-lg text-gray-500"><strong>&nbsp;</strong></p>
      <p className="mt-1 text-lg text-gray-500"><strong>12. &nbsp;Schlussbestimmungen</strong></p>
      <ol>
          <li className="mt-1 text-lg text-gray-500">Ungeachtet allenfalls abweichender Bestimmungen des Vertragsverh&auml;ltnisses ist Travelgram jederzeit berechtigt, die Plattform oder die Dienste von Travelgram per sofort einzustellen. Eine Benachrichtigung des Nutzers ist in diesen F&auml;llen nicht erforderlich.&nbsp;</li>
          <li className="mt-1 text-lg text-gray-500">Unwirksamkeit einer Klausel, ist diese durch eine solche zu ersetzen, die dem wirtschaftlichen Zweck der unwirksamen Bestimmung am n&auml;chsten kommt.</li>
          <li className="mt-1 text-lg text-gray-500">Sollte eine Partei ihr aus dem Vertragsverh&auml;ltnis zustehende Rechte nicht oder nicht rechtzeitig aus&uuml;ben, so f&uuml;hrt dies nicht zu einer Verwirkung oder einem Verlust dieser Rechte. Die Nichtaus&uuml;bung oder versp&auml;tete Aus&uuml;bung eines Rechts soll in keinem Fall dazu f&uuml;hren, dass dieses Recht nicht mehr ausge&uuml;bt werden kann.</li>
          <li className="mt-1 text-lg text-gray-500">Das Vertragsverh&auml;ltnis untersteht dem materiellen&nbsp;<strong>schweizerischen Recht</strong>, unter Ausschluss der Kollisionsnormen und von internationalen Abkommen.</li>
          <li className="mt-1 text-lg text-gray-500">Streitigkeiten, Meinungsverschiedenheiten oder Anspr&uuml;che aus oder in Zusammenhang mit diesem Vertragsverh&auml;ltnis, einschliesslich dessen G&uuml;ltigkeit, Ung&uuml;ltigkeit, Verletzung oder Aufl&ouml;sung, werden ausschliesslich durch die ordentlichen Gerichte am Gerichtsstand des&nbsp;<strong>Sitzes von Travelgram</strong> entschieden.</li>
          <li className="mt-1 text-lg text-gray-500">Grundlage der AGBs ist die deutsche Fassung. Die englische &Uuml;bersetzung dient lediglich zu Informationszwecken und stellt eine unverbindliche &Uuml;bersetzung dar.&nbsp;</li>
      </ol>
      <p className="mt-1 text-lg text-gray-500">&nbsp;</p>
      <p className="mt-1 text-lg text-gray-500">Gerichtsstand ist Z&uuml;rich.</p>
      <p className="mt-1 text-lg text-gray-500">10.07.2022</p>
      </div>
  </div>
    )
  }
  else{
    return(
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
    <div className="py-4 sm:py-5">
    <h1 className="text-4xl font-bold text-gray-500 mb-2 text-bold">Privacy policy of Travelgram GmbH</h1>
    <p className="mt-1 text-lg text-gray-500">&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">1. Scope and general principles</p>
    <p className="mt-1 text-lg text-gray-500">This privacy policy applies only to the website <a href="http://www.Travelgram.app">www.Travelgram.app</a> (hereinafter &quot;online - platform&quot;). We, Travelgram GmbH (hereinafter &quot;Travelgram&quot;), are the owners and operators of this online - platform and are responsible for it, as defined by the applicable data protection laws.&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">Your trust and the protection of your privacy is of great importance to us. We therefore take the protection and security of your personal data very seriously. Your data will therefore only be collected, processed, used and stored in compliance with the applicable laws on data protection and data security.</p>
    <p className="mt-1 text-lg text-gray-500">In this privacy policy we would like to inform you about the type, scope and purpose of the personal data we collect, use and process. Furthermore, you will be informed about your rights in this privacy policy.&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">The German text of this Privacy policy prevails. The English text serves information purposes only and is a non-binding convenience translation.&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">2. Data transmission via the internet</p>
    <p className="mt-1 text-lg text-gray-500">The internet is an open network which is available to everyone and does not therefore represent a secure environment. Although each data package is always transmitted in an encrypted form, this does not apply to the sender or recipient. Moreover, it is possible for data to be transmitted abroad and thus possibly to a country with a lower standard of data protection than Switzerland, even if the sender and recipient are located in Switzerland. Travelgram accepts no liability for the security of data transmitted via the internet.</p>
    <p className="mt-1 text-lg text-gray-500">&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">3. Collection of personal data&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">We collect personal data (any information that concerns an identified or identifiable person) to facilitate your usage and offer you the best possible service. The extent and type of data collection depends on whether you visit our online - platform only to access information or whether you make use of services we offer.</p>
    <p className="mt-1 text-lg text-gray-500">(a) Use of our online - platform to access information</p>
    <p className="mt-1 text-lg text-gray-500">If you use our online - platform purely for information purposes, you need not supply any personal data. We only collect and use the data concerning you which your internet browser automatically transmits, such as the pages accessed, the IP address, the internet browser used, the language and version of the internet browser used, the date and time of access etc.&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">When you visit our website for information purposes, we collect and use this data only in an anonymised form to enable you to visit our online - platform, for statistical purposes and to improve our services. By using our online - platform, you consent to our processing this data.&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">(b) Personal data which you supply to us and information which we receive through your use of the website</p>
    <p className="mt-1 text-lg text-gray-500">We collect such personal data and information to the extent that you provide it (e.g. when creating a profile on Travelgram, when booking, when receiving contact requests over Salesforce and when using our live chat system Intercom). Especially when you create a profile on Travelgram, various personal data is collected such as name, address, email, date of birth, telephone number, profile picture etc. along with other important data concerning social media post, profile, hastags etc. Electronic copies of your social media profil and network information and other important informations are also created. By sending us such data, you consent to our processing this data.&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">When you select a payment provider on our site for payment, this provider also receives certain personal data such as your name, address and account details. It is also possible that your credit card provider will carry out a credit check. Your payment provider will supply you with further details.&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">Furthermore, we collect information about the services you use and the way you use them, for example, the data that is exchanged between lessee and lessor when a booking is made via the online - platform, such as the name and telephone number of the lessee and the lessor, reservation/usage data, messages/contact forms etc. By using our online - platform and our services, you consent to our processing this data.&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">All of this data is treated as strictly confidential and only stored for as long as is necessary to process the contract and order. Moreover, we carry out all data processing procedures (e.g. collection, processing and transmission) in accordance with the statutory requirements.&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">4. How we use and process personal data</p>
    <p className="mt-1 text-lg text-gray-500">We store, process and use the information and personal data collected as per item 3 for the provision, maintenance, protection and improvement of the online - platform, i.e. to process a booking, to use other services on the online - platform, for customer support or to fullfil legal and contractual obligations.</p>
    <p className="mt-1 text-lg text-gray-500">If you use our live chat system Intercom or create a profile with us and log in, we can also record whether you or which users are currently on the online - platform.</p>
    <p className="mt-1 text-lg text-gray-500">We may also process and use your address and contact information for marketing and market research purposes. In particular, we evaluate the information and personal data collected as per item 3 in-house and determine where our lessees and lessors come from etc. For third-party marketing purposes, data is only passed on where this is permitted under the law.&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">5. Newsletter</p>
    <p className="mt-1 text-lg text-gray-500">On our online - platform we offer you the chance to subscribe to our newsletter for free. For this, apart from your declaration of consent, we require your email address. By sending your subscription to the newsletter you agree to our processing your data within the scope of this privacy policy.&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">You can unsubscribe from our newsletter at any time. You will find the link for this at the end of each newsletter. By doing so, you revoke your consent or object to continued use of your data for the purpose of dispatch of the newsletter.&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">6. Personal data forwarded by us</p>
    <p className="mt-1 text-lg text-gray-500">For the processing of each booking, the data relevant for the booking is forwarded to the lessee or lessor&nbsp;(within the scope of a purpose which is permissible according to this privacy policy). You hereby expressly consent to this forwarding of data.</p>
    <p className="mt-1 text-lg text-gray-500">Your personal data will otherwise not be passed on to third parties, unless it is necessary to process the contractual relationship, or you have given your express consent. We reserve the right to disclose the data due to applicable laws, official orders etc. or for other important reasons (e.g. investigation of possible violations of the terms of use, security deficiencies, serious technical problems, violation of third party rights, protection of property etc.). The right is expressly reserved to any processing of data by third-party services, e.g. cookies or social plug-ins (cf. item 7).</p>
    <p className="mt-1 text-lg text-gray-500">&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">7. Web tracking and services of third-party providers</p>
    <p className="mt-1 text-lg text-gray-500">7.1 General information</p>
    <p className="mt-1 text-lg text-gray-500">By registering on Travelgram, the user agrees to synchronize any of his data also on Foodgramer, Tripgramer and Flatgramer in order to be able to use the full functionality of the respective platform. Furthermore, the disclosure of the data is reserved due to applicable laws, official orders, etc. as well as for other important reasons (e.g. investigation of possible violations of the terms of use, security deficiencies, serious technical problems, violation of third-party rights, protection of property, etc.). We expressly reserve the right to process any data by third-party services, e.g. cookies or social media API connection. (see point 7).</p>
    <p className="mt-1 text-lg text-gray-500">7.2 Web analytics services</p>
    <p className="mt-1 text-lg text-gray-500">Travelgram uses web analytics services to analyse the use of the website in general and to get indications for improving our services. The web analytics services use cookies to collect data. The information generated in the process (e.g. IP address and browser type) may be transmitted to a server of the web analytics service in Switzerland or abroad and stored and processed there. The installation of cookies can be prevented as described in section 7.1.</p>
    <p className="mt-1 text-lg text-gray-500">We use web analytics services from the following providers:</p>
    <ul>
        <li>Google Analytics;</li>
        <li>Google Tag Manager;</li>
        <li>Hotjar;</li>
        <li>Bing;</li>
    </ul>
    <p className="mt-1 text-lg text-gray-500"><strong>Google Analytics</strong> and <strong>Google Tag Manager</strong> are web analytics services provided by Google Inc. (&quot;Google&quot;). Google Analytics uses &quot;cookies&quot;, which are text files placed on your computer, to help the website analyse how users use the site. The information generated by the cookie about the use of the website (including the user&apos;s IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google&apos;s behalf. Google will not associate the user&apos;s IP address with any other data held by Google. Users may refuse the use of cookies by selecting the appropriate settings on their browser, however please note that if you do this you may not be able to use the full functionality of the online - platform. By using the online - platform, you consent to the processing of data about you by Google in the manner and for the purposes set out above. Information on the &quot;Opt-Out Plug-in&quot; can be found here: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>; information on the Google privacy policy here: <a href="https://www.google.com/intl/de/policies/technologies/">https://www.google.com/intl/de/policies/technologies/</a> and on the Google Tag Manager here: <a href="https://marketingplatform.google.com/about/analytics/tag-manager/use-policy/">https://marketingplatform.google.com/about/analytics/tag-manager/use-policy/</a>.</p>
    <p className="mt-1 text-lg text-gray-500"><strong>Hotjar</strong> is a web analytics service provided by Hotjar Ltd. (&quot;Hotjar&quot;) (<a href="http://www.hotjar.com/">http://www.hotjar.com</a>, 3 Lyons Range, 20 Bisazza Street, Sliema SLM 1640, Malta, Europe), which enables Travelgram to analyse the use of the online - platform. Hotjar stores this data on servers in Europe (Ireland). Information about the opt-out plug-in can be found here: <a href="https://www.hotjar.com/opt-out">https://www.hotjar.com/opt-out</a></p>
    <p className="mt-1 text-lg text-gray-500"><strong>Bing</strong> is a web search engine with online marketing offerings from Microsoft Ireland Operations Limited (One Microsoft Place, South County Business Park, Leopardstown, Dublin 18, D18 PS21, Ireland). Bing enables Travelgram to evaluate the online marketing offers. You can find more information about Microsoft&apos;s privacy policy here: <a href="https://privacy.microsoft.com/de-de/privacystatement">https://privacy.microsoft.com/de-de/privacystatement</a></p>
    <p className="mt-1 text-lg text-gray-500">7.3 Social PlugIns and Social Media Account connection</p>
    <p className="mt-1 text-lg text-gray-500">Plugins of the social network Instagram, provider Facebook Inc., 1 Hacker Way, Menlo Park, California 94025, USA, are integrated on the online - platform. The Facebook plugins are recognisable by the Instagram logo or the &quot;description text&quot;). An overview of the Facebook plugins can be found here: <a href="https://developers.facebook.com/docs/plugins/">https://developers.facebook.com/docs/plugins/</a>. When users visit the online - platform, a direct connection is established between the user&apos;s browser and the Facebook server via the plug-in and API connection. Facebook thereby receives the information that you have visited the online - platform with your IP address. If you click on the Facebook &quot;Like&quot; button while you are logged into your Facebook account, you can link the content of the online - platform on your Facebook profile. This allows Facebook to associate the visit to the online - platform with the relevant user account. Travelgram points out that Travelgram has no knowledge of the content of the transmitted data or its use by Facebook. Further information on this can be found in Facebook&apos;s privacy policy at <a href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a>.</p>
    <p className="mt-1 text-lg text-gray-500">7.4 Other tools</p>
    <p className="mt-1 text-lg text-gray-500">Travelgram uses Intercom, a web analytics and chat service provided by Intercom Inc, 98 Battery Street, Suite 402, San Francisco, CA 94111, USA (&quot;Intercom&quot;) for statistical analysis of how visitors use this website. Intercom logs your page views and page activity. This data is used to create anonymous usage profiles that form a basis for web statistics. Intercom stores this data on servers in the USA. You can find more information about Intercom&apos;s data protection here: <a href="https://www.intercom.com/terms-and-policies#privacy">https://www.intercom.com/terms-and-policies#privacy</a>.</p>
    <p className="mt-1 text-lg text-gray-500">Travelgram uses the technical service provider Salesforce to send e-mail campaigns and to manage and respond to customer enquiries and customer information. Salesforce is a service of salesforce.com inc, Salesforce Tower, 415 Mission Street, 3rd floor, San Francisco, CA 94105, USA. Salesforce provides extensive analytics about how our newsletters are opened and used. These analyses are group-related and are not used by us for individual evaluation. The data collected may be collected, transferred and stored by Salesforce in the United States of America and in other countries in which Salesforce operates, including countries outside the European Economic Area. For more information about Salesforce&apos;s privacy policy, please visit <a href="https://www.salesforce.com/company/privacy/">https://www.salesforce.com/company/privacy/</a>.</p>
    <p className="mt-1 text-lg text-gray-500">By using this online - platform, you consent to the processing of data collected about you in the manner and for the purposes set out above.</p>
    <p className="mt-1 text-lg text-gray-500">&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">8. Data security</p>
    <p className="mt-1 text-lg text-gray-500">Travelgram takes appropriate technical and organisational security measures to protect personal data, especially against accidental or intentional manipulation, loss, destruction or attacks by unauthorised persons. Our security measures are continuously improved in line with technological developments.</p>
    <p className="mt-1 text-lg text-gray-500">&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">9. User rights</p>
    <p className="mt-1 text-lg text-gray-500">You have the right at any time to demand confirmation from us as to whether personal data of yours is processed.&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">If we process your personal data, you have a right to access, in particular with regard to the following information: (a) what data is processed; (b) the purpose for which it is processed; (c) the categories of personal data which we process; (d) the recipients or categories of recipients to whom your personal data has been disclosed; (e) if possible, the planned duration for which your personal data will be stored; (f) the existence of a right to rectification or erasure of personal data concerning you or to the restriction of processing by us or a right of objection to processing; (g) where the personal data was not collected directly from you, all available information about the source of the data; (h) the existence of automated decision-making. Furthermore, you have the right to know whether your personal data has been transmitted to a third country or an international organisation.&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">Furthermore, you have the right at any time to demand the immediate rectification, completion, restriction, blocking or erasure of the personal data stored about you. Should legal regulations not allow erasure, your personal data will be blocked instead. Moreover, you have the right to be informed by us of any rectification, completion, restriction, blocking or erasure of your data. In the event of any breach of the protection of personal data, we shall also inform you if this entails a high risk for personal rights and freedoms.&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">The officer responsible for this can be reached via Christian.Szklennik@travelgram.App or Joerg.Hoenigmann@travelgramm.App or via Travelgram GmbH, Farmanstrasse 47, 8152 Zurich. The exercise of your above-mentioned rights is free of charge for you.&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">If you do not agree to our data processing, you can also notify the Federal Data Protection and Information Commissioner (FDPIC) and/or the responsible supervisory authorities in the EU.&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">10. Adaptation of the data privacy policy</p>
    <p className="mt-1 text-lg text-gray-500">Travelgram reserves the right to adapt this privacy policy at any time in line with legal requirements. The amendments shall be binding.</p>
    <p className="mt-1 text-lg text-gray-500">&nbsp;</p>
    <p className="mt-1 text-lg text-gray-500">Zurich, 07.10.2022</p>
    </div>
</div>
    )
  }
}
