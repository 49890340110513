/* This example requires Tailwind CSS v2.0+ */
// import React, { Component } from 'react'
import {
    ChevronLeftIcon,
    FilterIcon,
    MailIcon,
    PhoneIcon,
    SearchIcon,
    UserGroupIcon,
} from "@heroicons/react/solid";
import { React, useState, useEffect, useRef } from "react";

import Post from "../post/Post";
import { axiosInstance } from "../../utils/axios";
import { getUser } from "../../utils/storage";
import UserListModal from "./UserListModal";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
const userLogged = getUser();

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
// class UserProfile extends Component{

//   componentDidMount(){
//     if (window.location.href.indexOf("archived") != -1){
//       console.log("archiveeed");
//       this.setState({
//         archived: 1
//       },()=>{
//         this.getPosts();
//       });
//     }
//     else{
//       this.getPosts();
//     }
//   }

export default function UserProfile(props) {
    const { username } = useParams();

    const [posts, setPosts] = useState([]);
    const [followers, setFollowers] = useState([]);
    const [following, setFollowing] = useState([]);
    const [archived, setArchived] = useState(0);
    const [showFollowersModal, setShowFollowersModal] = useState(false);
    const [showFollowingModal, setShowFollowingModal] = useState(false);
    const [user, setUser] = useState({});

    const [followed, setFollowed] = useState(false);
    const [userFollow, setUserFollow] = useState(null);

    const { t, i18n } = useTranslation();

    // useEffect(()=>{
    //   if(Object.keys(user).length === 0) {
    //     console.log("blla 1");
    //     getUserFromUsername();
    //   }
    //   else{
    //     console.log("blla 2");
    //     return;
    //   }
    // },[user])
    useEffect(() => {
        getUserFromUsername();
    }, []);

    useEffect(() => {
        if (window.location.href.indexOf("archived") != -1) {
            setArchived(1);
            getPosts();
        } 
       
        else {
            getPosts();

        }
        getFollow();
    }, [user, archived]);

    function getUserFromUsername() {
        axiosInstance
            .get(
                window.REACT_APP_API_ENDPOINT +
                    "/api/users/username/" +
                    username
            )
            .then(function (response) {
                console.log("response user muse", response.data);
                setUser(response.data);
                // userRef.current == response.data;
                // useEffect(() => { setUser(response.data) }, [])
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }


    async function getPosts() {
        await axiosInstance
            .get(
                window.REACT_APP_API_ENDPOINT +
                    "/api/user/" +
                    user.id +
                    "/posts/" +
                    archived
            )
            .then(function (response) {

                setPosts(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }

    function getFollowers() {
        axiosInstance
            .get(
                window.REACT_APP_API_ENDPOINT +
                    "/api/users/" +
                    user.id +
                    "/followers"
            )
            .then(function (response) {

                setFollowers(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }

    function onUserModalClick() {
        setShowFollowersModal(false);
        setShowFollowingModal(false);
    }

    function onFollowersShow() {
        getFollowers();
        setShowFollowersModal(true);
        setShowFollowingModal(false);
    }

    function onFollowingShow() {
        getFollowing();
        setShowFollowersModal(false);
        setShowFollowingModal(true);
    }

    function getFollowing() {
        axiosInstance
            .get(
                window.REACT_APP_API_ENDPOINT +
                    "/api/users/" +
                    user.id +
                    "/following"
            )
            .then(function (response) {

                setFollowing(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }

    async function getFollow() {
        // axios.get('http://localhost:8000/api/users/1')
        //     .then(function (response) {
        //         console.log("response", response.data);
        //         setUser(response.data);
        //     })
        //     .catch(function (error) {
        //         // handle error
        //         console.log(error);
        //     })

        const res = await axiosInstance.get(
            window.REACT_APP_API_ENDPOINT +
                "/api/follows/checkFollow/" +
                user.id +
                "/" +
                userLogged.id
        );

        let data = await res.data;
        if (data.length > 0) {
            setFollowed(true);
            setUserFollow(data[0]);
        }
    }

    function onUserFollow() {
        if (followed == false) {
            const data = {
                user_id: user.id,
                follower_id: userLogged.id,
            };

            axiosInstance
                .post(window.REACT_APP_API_ENDPOINT + "/api/follows", data)
                .then((response) => {
                    console.log(response);

                    setFollowed(true);
                    setUserFollow(response.data.follow);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response);
                        console.log("server responded");
                    } else if (error.request) {
                        console.log("network error");
                    } else {
                        console.log(error);
                    }
                });
        } else {
            axiosInstance
                .delete(
                    `${window.REACT_APP_API_ENDPOINT}/api/follows/${userFollow.id}`
                )
                .then((res) => {
                    console.log(res);
                    console.log(res.data);
                    setFollowed(false);

                    // const posts = postLikes.filter(item => item.id !== postLike.id);
                    // setPostLikes(posts);
                });
        }
    }

    const postList = posts.map((post) => (
        <Post
            post={post}
            type={
                userLogged && userLogged.username == username
                    ? "profile"
                    : "feed"
            }
            archived={archived == 1 ? true : false}
        />
    ));
    return (
        <div className="min-h-full flex flex-col justify-center">
            {/* <div className="flex items-center mt-3">
          <div>
            <img
              className="inline-block h-48 w-48 rounded-full"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </div>
          <div className="ml-3">
            <p className="text-2xl font-medium text-gray-700 group-hover:text-gray-900">Tom Cook</p>
            <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">View profile</p>
          </div>
        </div> */}
            <div className="h-full flex">
                <div className="flex-1 relative z-0 flex overflow-hidden">
                    <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
                        {showFollowersModal && (
                            <UserListModal
                                users={followers}
                                type="Followers"
                                onUserModalClick={onUserModalClick}
                            />
                        )}
                        {showFollowingModal && (
                            <UserListModal
                                users={following}
                                type="Following"
                                onUserModalClick={onUserModalClick}
                            />
                        )}
                        <article>
                            {/* Profile header */}
                            <div>
                                <div>
                                    <img
                                        className="h-32 w-full object-cover lg:h-48"
                                        src={
                                            "https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                    <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                                        <div className="flex">
                                            <img
                                                className="h-24 w-24 rounded-full object-cover ring-4 ring-white sm:h-32 sm:w-32"
                                                src={
                                                    user.file
                                                        ?
                                                          user.file
                                                        : "https://imgur.com/E0h3MGt.png"
                                                }
                                                alt=""
                                            />
                                        </div>
                                        <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                                            <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                                                <h1 className="text-2xl text-left font-bold text-gray-900 truncate">
                                                    {user.name} {user.last_name}
                                                </h1>
                                                {username ==
                                                userLogged.username ? (
                                                    ""
                                                ) : (
                                                    <div className="flex-shrink-0">
                                                        <button
                                                            onClick={
                                                                onUserFollow
                                                            }
                                                            type="button"
                                                            className="inline-flex items-center px-3 py-0.5 rounded-full bg-rose-50 text-sm font-medium text-rose-700 hover:bg-rose-100"
                                                        >
                                                            {/* <PlusSmIcon className="-ml-1 mr-0.5 h-5 w-5 text-rose-400" aria-hidden="true" /> */}
                                                            {/* <span>{followed? "Unfollow": "Follow"}</span> */}
                                                            <span>
                                                                {followed
                                                                    ? t(
                                                                          "user.unfollow"
                                                                      )
                                                                    : t(
                                                                          "user.follow"
                                                                      )}
                                                            </span>
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                                                <button
                                                    onClick={onFollowersShow}
                                                    type="button"
                                                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                                >
                                                    {user.followers}
                                                    <span>
                                                      &nbsp;  {t("user.followers")}
                                                    </span>
                                                </button>
                                                <button
                                                    onClick={onFollowingShow}
                                                    type="button"
                                                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                                >
                                                    {user.following}
                                                    <span>
                                                        &nbsp; {t("user.following")}
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
                                        <h1 className="text-2xl font-bold text-gray-900 truncate text-left">
                                            {user.name} {user.last_name}
                                        </h1>
                                        {username == userLogged.username ? (
                                            ""
                                        ) : (
                                            <div className="flex-shrink-0">
                                                <button
                                                    onClick={onUserFollow}
                                                    type="button"
                                                    className="inline-flex items-center px-3 py-0.5 rounded-full bg-rose-50 text-sm font-medium text-rose-700 hover:bg-rose-100"
                                                >
                                                    {/* <PlusSmIcon className="-ml-1 mr-0.5 h-5 w-5 text-rose-400" aria-hidden="true" /> */}
                                                    <span>
                                                        {followed
                                                            ? t("user.unfollow")
                                                            : t("user.follow")}
                                                    </span>
                                                    {/* <span>{"Follow1"}</span> */}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* Description list */}
                            <div className="mt-6 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                    {/* {Object.keys(profile.fields).map((field) => (
                <div key={field} className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">{field}</dt>
                  <dd className="mt-1 text-sm text-gray-900">{profile.fields[field]}</dd>
                </div>
              ))} */}
                                    <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500 text-left">
                                            {user.bio && "About"}
                                        </dt>
                                        <dd
                                            className="mt-1 max-w-prose text-sm text-gray-900 space-y-5 text-left"
                                            dangerouslySetInnerHTML={{
                                                __html: user.bio,
                                            }}
                                        />
                                    </div>
                                </dl>
                            </div>

                            {/* Tabs */}
                            <div className="mt-6 sm:mt-2 2xl:mt-5">
                                <div className="border-b border-gray-200">
                                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                        <nav
                                            className="-mb-px flex space-x-8"
                                            aria-label="Tabs"
                                        >
                                            {/* {tabs.map((tab) => ( */}
                                            {username == userLogged.username ? (
                                                <>
                                                    {" "}
                                                    <a
                                                        key={"posts"}
                                                        href={
                                                            "/" +
                                                            i18n.language +
                                                            "/profile/" +
                                                            userLogged.username
                                                        }
                                                        className={classNames(
                                                            "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                                            "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                                                        )}
                                                    >
                                                        {t("user.posts")}
                                                    </a>
                                                    <a
                                                        key={"archived"}
                                                        href={
                                                            "/" +
                                                            i18n.language +
                                                            "/profile/" +
                                                            userLogged.username +
                                                            "/archived"
                                                        }
                                                        className={classNames(
                                                            "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                                            "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                                                        )}
                                                    >
                                                        {t("user.archived")}
                                                    </a>
                                             
                                                </>
                                            ) : (
                                                ""
                                            )}

                                            {/* ))} */}
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </main>
                </div>
            </div>

            <ul
                role="list"
                className="w-full max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-3 mt-14"
            >
                {postList}
            </ul>
        </div>
    );
}
