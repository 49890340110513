import React from 'react'
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon, XIcon } from '@heroicons/react/outline'
import { useTranslation } from "react-i18next";
import {
   
    HeartIcon,
    ChevronDoubleRightIcon,
  
  } from '@heroicons/react/solid'

export default function PostModal(props) {
    const [open, setOpen] = useState(true)
    const { i18n, t } = useTranslation();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={props.onCloseClick}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
             
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3">
               
            <div className="w-12/12 md:w-5/12 lg:w-5/12  flex-col">
                <div className="mb-5 lg:absolute md:absolute md:bottom-0 lg:bottom-0">
                  <div className="flex items-center mb-4">
                    <div className="flex-shrink-0 mr-2">
                        <img className="h-10 w-10 rounded-full object-cover" src={props.post.image? props.post.image : 'https://imgur.com/E0h3MGt.png'} alt="" />
                    </div>
                        <a href={"/"+i18n.language+"/profile/"+props.post.username} className="text-sm font-medium text-gray-900 text-left">
                            {props.post.name}
                        </a>
                      </div>

                        <p className="mb-4">{props.post.caption}</p>
                {props.tags.length>0 && props.tags.map((tag)=>
                                <a className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#{tag.name.en} </a>
                              )}

                  <span className="block items-center text-sm mb-2 mt-2">
                        <a href={props.post.hotel_link} target="_blank" className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                        <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                        <span className="font-medium text-gray-900">{t('post.see_hotel')}</span>
                        </a>
                    </span>
                </div>
                <div className="min-w-0 flex-1">
                   
                    <p className="text-sm text-gray-500 text-left mb-4">
                    <span className="hover:underline">
                    {/* new Date().toLocaleDateString(); */}
                        {/*<time dateTime={new Date(props.post.created_at).toDateString().split(' ').slice(1).join(' ')}>{new Date(props.post.created_at).toDateString().split(' ').slice(1).join(' ')}</time>*/}
                    </span> <span className="inline-block w-2 h-2 rounded-full bg-gray-500 mr-2 ml-2"></span> 
                    <span>
                        {props.post.location? props.post.location : ''}
                    </span>
                    </p>

                    <p className="text-sm text-gray-500 text-left">

                    <span>
                        {props.post.hotel_price? props.post.hotel_price + '€' : ''}
                    </span>
                    </p>
                </div>
           

                    {/* <span className="block items-center text-sm mb-2 absolute bottom-0 right-0 pr-4 pb-4">
                        <button type="button" className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                        <HeartIcon className="h-5 w-5" aria-hidden="true" color={props.liked? "red": "currentColor"}/>
                        <span className="font-medium text-gray-900">{props.postLikes.length}</span>
                        <span className="sr-only">likes</span>
                        </button>
                    </span> */}
                    
                    
               
                 
                  
                </div>
                <div className="overflow-hidden relative w-12/12 md:w-7/12 lg:w-7/12 h-56 sm:h-64 xl:h-80 2xl:h-96">
                    <img className="block h-full object-cover w-full" src={props.post.file} alt="" />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
