export const getUser = () => {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    else return null;
  };


  export const setUserSession = (token, user) => {
    localStorage.setItem("hotelgramer_token", token);
    localStorage.setItem("user", JSON.stringify(user));
  };

  export const setRememberUserSession = (token) => {
    sessionStorage.setItem("hotelgramer_remember_token", token);
  };

  export const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  export const setUserCookie = (cname, cvalue, rememberMe) => {
    let objectString = JSON.stringify(cvalue);
    if(rememberMe == true){
      const d = new Date();
      d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
      let expires = "expires="+d.toUTCString();
      document.cookie = cname + "=" + objectString + ";" + expires + ";path=/";
    }
    else{
      
      document.cookie = cname + "=" + objectString + ";";
    }
  
  }

  export const getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  export const existsCookie = (name) => {
    if (document.cookie.indexOf(name) > -1 ) {
      return true;
    }    
    else{
      return false;
    }
  }

  export const eraseCookie = (name) => {
      document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

