/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          sky: colors.sky,
          teal: colors.teal,
        },
      },
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { React, Fragment, useState } from 'react'
import { Disclosure, Menu, Switch, Transition } from '@headlessui/react'
import { SearchIcon } from '@heroicons/react/solid'
import {
  BellIcon,
  CogIcon,
  CreditCardIcon,
  KeyIcon,
  MenuIcon,
  UserCircleIcon,
  ViewGridAddIcon,
  XIcon,
  CurrencyEuroIcon,
  HomeIcon
} from '@heroicons/react/outline'
import { getUser, setUserSession } from '../../utils/storage';
import { useTranslation } from 'react-i18next';

const userLogged = getUser();

const user = {
  name: 'Debbie Lewis',
  handle: 'deblewis',
  email: 'debbielewis@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80',
}
const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Jobs', href: '#', current: false },
  { name: 'Applicants', href: '#', current: false },
  { name: 'Company', href: '#', current: false },
]
// const subNavigation = [
//   { name: 'Profile', href: '#', icon: UserCircleIcon, current: true },
//   { name: 'Account', href: '/en/settings/deactivate', icon: CogIcon, current: false },
//   { name: 'Password', href: '/en/settings/password', icon: KeyIcon, current: false },
//   { name: 'Notifications', href: '#', icon: BellIcon, current: false },
//   { name: 'Billing', href: '#', icon: CreditCardIcon, current: false },
//   { name: 'Integrations', href: '#', icon: ViewGridAddIcon, current: false },
// ]
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function UserSettings(props) {
  const { t, i18n } = useTranslation();

  return (
    <div>




<Disclosure as="div" className="relative bg-sky-700 pb-32 overflow-hidden">
        {({ open }) => (
          <>
           
            <div
              aria-hidden="true"
              className={classNames(
                open ? 'bottom-0' : 'inset-y-0',
                'absolute inset-x-0 left-1/2 transform -translate-x-1/2 w-full overflow-hidden lg:inset-y-0'
              )}
            >
              <div className="absolute inset-0 flex">
                <div className="h-full w-1/2" style={{ backgroundColor: '#0a527b' }} />
                <div className="h-full w-1/2" style={{ backgroundColor: '#065d8c' }} />
              </div>
              <div className="relative flex justify-center">
                <svg
                  className="flex-shrink-0"
                  width={1750}
                  height={308}
                  viewBox="0 0 1750 308"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M284.161 308H1465.84L875.001 182.413 284.161 308z" fill="#0369a1" />
                  <path d="M1465.84 308L16.816 0H1750v308h-284.16z" fill="#065d8c" />
                  <path d="M1733.19 0L284.161 308H0V0h1733.19z" fill="#0a527b" />
                  <path d="M875.001 182.413L1733.19 0H16.816l858.185 182.413z" fill="#0a4f76" />
                </svg>
              </div>
            </div>
            <header className="relative py-10">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-white">Settings</h1>
              </div>
            </header>
          </>
        )}
      </Disclosure>

     

      <main className="relative -mt-32">
        <div className="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
              <aside className="py-6 lg:col-span-3">
                <nav className="space-y-1">
                
                    {/* <a
                        key={"dashboard"}
                        href={"/"+i18n.language+"/settings"}
                        className={classNames(
                          props.type == "dashboard"?
                          'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                        :'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                          'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                        )}
                        ><HomeIcon
                        className={classNames(
                          props.type == "dashboard"?
                          'text-teal-500 group-hover:text-teal-500'
                          :'text-gray-400 group-hover:text-gray-500',
                          'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      <span className="truncate">Dashboard</span>
                    </a> */}

                       <a
                      key={"Profile"}
                      href={"/"+i18n.language+"/settings/profile"}
                      className={classNames(
                        props.type == "profile"?
                        'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                       :'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                        'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                      )}
                      ><UserCircleIcon
                      className={classNames(
                        props.type == "profile"?
                        'text-teal-500 group-hover:text-teal-500'
                        :'text-gray-400 group-hover:text-gray-500',
                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    <span className="truncate">{t('settings.profile.title')}</span>
                  </a>

                    {/* <a
                      key={"Account"}
                      href={"/"+i18n.language+"/settings/deactivate"}
                      className={classNames(
                        props.type == "account"?
                        'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                       :'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                        'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                      )}
                      ><CogIcon
                      className={classNames(
                        props.type == "account"?
                        'text-teal-500 group-hover:text-teal-500'
                        :'text-gray-400 group-hover:text-gray-500',
                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    <span className="truncate">Account</span>
                  </a> */}

                  <a
                      key={"Password"}
                      href={"/"+i18n.language+"/settings/password"}
                      className={classNames(
                        props.type == "password"?
                        'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                       :'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                        'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                      )}
                      ><KeyIcon
                      className={classNames(
                        props.type == "password"?
                        'text-teal-500 group-hover:text-teal-500'
                        :'text-gray-400 group-hover:text-gray-500',
                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    <span className="truncate">{t('settings.password.title')}</span>
                  </a>

                  {/* <a
                      key={"bank-details"}
                      href={"/"+i18n.language+"/settings/bank"}
                      className={classNames(
                        props.type == "bank-details"?
                        'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                       :'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                        'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                      )}
                      ><CurrencyEuroIcon
                      className={classNames(
                        props.type == "bank-details"?
                        'text-teal-500 group-hover:text-teal-500'
                        :'text-gray-400 group-hover:text-gray-500',
                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    <span className="truncate">Bank Details</span>
                  </a> */}

                  {/* item.icon
                      className={classNames(
                        item.current
                          ? 'text-teal-500 group-hover:text-teal-500'
                          : 'text-gray-400 group-hover:text-gray-500',
                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                      )} */}
                      
                </nav>
              </aside>

              {props.children}

            </div>
          </div>
        </div>
      </main>
    </div>
  )
}