import React from 'react'
import firstImage from '../../assets/images/influencer-image1.png';
import secondImage from '../../assets/images/influencer-image2.png';
import thirdImage from '../../assets/images/influencer-image3.png';
import fourthImage from '../../assets/images/influencer-image4.png';
import fifthImage from '../../assets/images/influencer-image5.png';
import { useTranslation } from 'react-i18next';

export default function Influencer() {
  const { t } = useTranslation();

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
      {/* <div className="py-4 sm:py-5">
            <h1 className="text-3xl font-bold text-gray-500 mb-2 text-bold">{t('influencer.title')}</h1>
            <h3 className="text-medium font-bold text-gray-500 mb-2">{t('influencer.subtitle')} </h3>
            <p className="mt-1 text-xl text-gray-500 mb-4">
              {t('influencer.description')} 
            </p>
            <dt className="text-xl font-bold text-gray-500 mb-2">{t('influencer.how-it-works.title')}  </dt>
            <ol className="list-decimal pl-10 mb-4">
              <li className="mt-1 text-lg text-gray-500">{t('influencer.how-it-works.title')}</li>
              <li className="mt-1 text-lg text-gray-500">{t('influencer.how-it-works.item-1')}</li>
              <li className="mt-1 text-lg text-gray-500">{t('influencer.how-it-works.item-2')} </li>
              <li className="mt-1 text-lg text-gray-500">{t('influencer.how-it-works.item-3')} </li>
              <li className="mt-1 text-lg text-gray-500">{t('influencer.how-it-works.item-4')} </li>
              <li className="mt-1 text-lg text-gray-500">{t('influencer.how-it-works.item-5')} </li>
              <li className="mt-1 text-lg text-gray-500">{t('influencer.how-it-works.item-6')} </li>
              <li className="mt-1 text-lg text-gray-500">{t('influencer.how-it-works.item-7')}</li>
            </ol>

            <dt className="text-lg font-bold text-gray-500 mb-2">{t('influencer.advantages.title')}</dt>
            <ul>
              <li className="flex items-center">
                <img src={firstImage} className="w-7 h-7 mr-4"/>
                <p className="mt-1 text-lg text-gray-500">{t('influencer.advantages.item-1')}</p>
              </li>
              <li className="flex items-center">
                <img src={secondImage} className="w-7 h-7 mr-4"/>
                <p className="mt-1 text-lg text-gray-500">{t('influencer.advantages.item-2')}</p>
              </li>
              <li className="flex items-center">
                <img src={thirdImage} className="w-7 h-7 mr-4"/>
                <p className="mt-1 text-lg text-gray-500">{t('influencer.advantages.item-3')}</p>
              </li>
              <li className="flex items-center">
                <img src={fourthImage} className="w-7 h-7 mr-4"/>
                <p className="mt-1 text-lg text-gray-500">{t('influencer.advantages.item-4')}</p>
              </li>
              <li className="flex items-center">
                <img src={fifthImage} className="w-7 h-7 mr-4"/>
                <p className="mt-1 text-lg text-gray-500">{t('influencer.advantages.item-5')}</p>
              </li>
             
            </ul>
            </div> */}
        </div>
  
  )
}
