import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "../utils/axios";
import { getUser } from "../utils/storage";
const userLogged = getUser();

export default function Analytics() {
    const { i18n, t } = useTranslation();

    const [bankName, setBankName] = useState("");
    const [clearingNumber, setClearingNumber] = useState("");
    const [bankAddress, setBankAddress] = useState("");
    const [iban, setIban] = useState("");
    const [accNumber, setAccNumber] = useState("");

    const [bankNameError, setBankNameError] = useState("");
    const [clearingNumberError, setClearingNumberError] = useState("");
    const [bankAddressError, setBankAddressError] = useState("");
    const [ibanError, setIbanError] = useState("");
    const [accNumberError, setAccNumberError] = useState("");

    const [edit, setEdit] = useState(false);
    const [bankID, setBankID] = useState(0);

    const [clicksNumber, setClicksNumber] = useState(0);
    const [clickPercentage, setClickPercentage] = useState(0);
    const [bookingsNumber, setBookingsNumber] = useState(0);
    const [bookingsPercentage, setBookingsPercentage] = useState(0);
    const [earningsNumber, setEarningsNumber] = useState(0);
    const [earningsPercentage, setEarningsPercentage] = useState(0);
    const [comissionNumber, setComissionNumber] = useState(0);
    const [comissionPercentage, setComissionPercentage] = useState(0);

    useEffect(() => {
        getBankDetails();
        getUserAnalytics();
    }, []);

    async function getUserAnalytics() {
        const res = await axiosInstance.get(
            window.REACT_APP_API_ENDPOINT +
                "/api/users/" +
                userLogged.id +
                "/analytics"
        );

        let data = await res.data;

        setBookingsNumber(data[0].bookings_number);
        setBookingsPercentage(data[0].bookings_percentage);
        setClicksNumber(data[0].clicks_number);
        setClickPercentage(data[0].clicks_percentage);
        setComissionNumber(data[0].comission_number);
        setComissionPercentage(data[0].comission_percentage);
        setEarningsNumber(data[0].earnings_number);
        setEarningsPercentage(data[0].earnings_percentage);
    }

    async function getBankDetails() {
        const res = await axiosInstance.get(
            window.REACT_APP_API_ENDPOINT +
                "/api/users/" +
                userLogged.id +
                "/bankdetails"
        );

        let data = await res.data;

        setAccNumber(data[0].acc_number);
        setBankAddress(data[0].adresse);
        setClearingNumber(data[0].clearing_number);
        setIban(data[0].iban);
        setBankName(data[0].name);
        setBankID(data[0].id);
        if (data.length > 0) {
            setEdit(true);
        }
    }

    function updateBankDetails(e) {
        e.preventDefault();

        if (bankName == "") {
            setBankNameError("name is required");
        }

        if (clearingNumber == "") {
            setClearingNumberError("clearing number is required");
        }

        if (bankAddress == "") {
            setBankAddressError("bank address is required");
        }

        if (iban == "") {
            setIbanError("iban is required");
        }

        if (accNumber == "") {
            setAccNumberError("acc number is required");
        }

        if (
            bankName == "" &&
            clearingNumber == "" &&
            bankAddress == "" &&
            iban == "" &&
            accNumber == ""
        ) {
            return;
        }

        const data = {
            name: bankName,
            clearing_number: clearingNumber,
            adresse: bankAddress,
            iban: iban,
            acc_number: accNumber,
        };

        axiosInstance
            .put(
                window.REACT_APP_API_ENDPOINT + "/api/bankdetails/" + bankID,
                data
            )
            .then((response) => {
                console.log(response);

                if (response.status == 200) {
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response);
                    console.log("server responded");
                } else if (error.request) {
                    console.log("network error");
                } else {
                    console.log(error);
                }
            });
    }

    function createBankDetails(e) {
        e.preventDefault();

        if (bankName == "") {
            setBankNameError("name is required");
        }

        if (clearingNumber == "") {
            setClearingNumberError("clearing number is required");
        }

        if (bankAddress == "") {
            setBankAddressError("bank address is required");
        }

        if (iban == "") {
            setIbanError("iban is required");
        }

        if (accNumber == "") {
            setAccNumberError("acc number is required");
        }

        if (
            bankName == "" &&
            clearingNumber == "" &&
            bankAddress == "" &&
            iban == "" &&
            accNumber == ""
        ) {
            return;
        }

        const data = {
            name: bankName,
            clearing_number: clearingNumber,
            adresse: bankAddress,
            iban: iban,
            acc_number: accNumber,
            user_id: userLogged.id,
        };

        axiosInstance
            .post(window.REACT_APP_API_ENDPOINT + "/api/bankdetails", data)
            .then((response) => {
                console.log(response);

                if (response.status == 200) {
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response);
                    console.log("server responded");
                } else if (error.request) {
                    console.log("network error");
                } else {
                    console.log(error);
                }
            });
    }

    return (
        <div className="py-10">
            <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="py-6 py-6 px-4">
                    <div className="grid grid-cols-1 items-start">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Last 30 days
                        </h3>

                        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                            <div className="relative overflow-hidden rounded-lg bg-white px-2 pt-5 pb-12 shadow sm:px-2 sm:pt-2">
                                <dt>
                                    <div className="absolute rounded-md bg-indigo-500 p-2">
                                        <svg
                                            className="h-6 w-6 text-white"
                                            x-description="Heroicon name: outline/users"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                                            ></path>
                                        </svg>
                                    </div>
                                    <p className="ml-12 truncate text-sm font-medium text-gray-500">
                                        Clicks
                                    </p>
                                </dt>
                                <dd className="ml-12 flex items-baseline pb-6 sm:pb-7">
                                    <p className="text-sm font-semibold text-gray-900">
                                        {clicksNumber}
                                    </p>
                                    <p className="ml-2 flex items-baseline text-sm font-semibold text-green-600">
                                        <svg
                                            className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                                            x-description="Heroicon name: mini/arrow-up"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span className="sr-only">
                                            {" "}
                                            Increased by{" "}
                                        </span>
                                        {clickPercentage} %
                                    </p>
                                </dd>
                            </div>

                            <div className="relative overflow-hidden rounded-lg bg-white px-2 pt-5 pb-12 shadow sm:px-2 sm:pt-2">
                                <dt>
                                    <div className="absolute rounded-md bg-indigo-500 p-2">
                                        <svg
                                            className="h-6 w-6 text-white"
                                            x-description="Heroicon name: outline/envelope-open"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z"
                                            ></path>
                                        </svg>
                                    </div>
                                    <p className="ml-12 truncate text-sm font-medium text-gray-500">
                                        Bookings
                                    </p>
                                </dt>
                                <dd className="ml-12 flex items-baseline pb-6 sm:pb-7">
                                    <p className="text-sm font-semibold text-gray-900">
                                        {bookingsNumber}
                                    </p>
                                    <p className="ml-2 flex items-baseline text-sm font-semibold text-green-600">
                                        <svg
                                            className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                                            x-description="Heroicon name: mini/arrow-up"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span className="sr-only">
                                            {" "}
                                            Increased by{" "}
                                        </span>
                                        {bookingsPercentage} %
                                    </p>
                                </dd>
                            </div>

                            <div className="relative overflow-hidden rounded-lg bg-white px-2 pt-5 pb-12 shadow sm:px-2 sm:pt-2">
                                <dt>
                                    <div className="absolute rounded-md bg-indigo-500 p-2">
                                        <svg
                                            className="h-6 w-6 text-white"
                                            x-description="Heroicon name: outline/cursor-arrow-rays"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59"
                                            ></path>
                                        </svg>
                                    </div>
                                    <p className="ml-12 truncate text-sm font-medium text-gray-500">
                                        Earnings
                                    </p>
                                </dt>
                                <dd className="ml-12 flex items-baseline pb-6 sm:pb-7">
                                    <p className="text-sm font-semibold text-gray-900">
                                        {earningsNumber}
                                    </p>
                                    <p className="ml-2 flex items-baseline text-sm font-semibold text-red-600">
                                        <svg
                                            className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                                            x-description="Heroicon name: mini/arrow-down"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span className="sr-only">
                                            {" "}
                                            Decreased by{" "}
                                        </span>
                                        {earningsPercentage} %
                                    </p>
                                </dd>
                            </div>

                            <div className="relative overflow-hidden rounded-lg bg-white px-2 pt-5 pb-12 shadow sm:px-2 sm:pt-2">
                                <dt>
                                    <div className="absolute rounded-md bg-indigo-500 p-2">
                                        <svg
                                            className="h-6 w-6 text-white"
                                            x-description="Heroicon name: outline/cursor-arrow-rays"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59"
                                            ></path>
                                        </svg>
                                    </div>
                                    <p className="ml-12 truncate text-sm font-medium text-gray-500">
                                        Comission
                                    </p>
                                </dt>
                                <dd className="ml-12 flex items-baseline pb-6 sm:pb-7">
                                    <p className="text-sm font-semibold text-gray-900">
                                        {comissionNumber}
                                    </p>
                                    <p className="ml-2 flex items-baseline text-sm font-semibold text-red-600">
                                        <svg
                                            className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                                            x-description="Heroicon name: mini/arrow-down"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span className="sr-only">
                                            {" "}
                                            Decreased by{" "}
                                        </span>
                                        {comissionPercentage} %
                                    </p>
                                </dd>
                            </div>
                        </dl>
                    </div>

                    <form className="divide-y divide-gray-200 lg:col-span-9">
                        {/* Profile section */}
                        <div className="py-6">
                            <div>
                                <h2 className="text-lg leading-6 font-medium text-gray-900">
                                    {t("analytics.bank_title")}
                                </h2>
                                <p className="mt-1 text-sm text-gray-500">
                                    {t("analytics.bank_description")}
                                </p>
                            </div>

                            <div className="mt-6 grid grid-cols-12 gap-6">
                                <div className="col-span-12 sm:col-span-6">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Bank Name
                                    </label>
                                    <input
                                        type="text"
                                        name="bank-name"
                                        id="bank-name"
                                        onChange={(e) => {
                                            setBankName(e.target.value);
                                        }}
                                        value={bankName}
                                        autoComplete="bank-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                                    />

                                    {bankNameError && (
                                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                            {bankNameError}{" "}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Clearing Nummer
                                    </label>
                                    <input
                                        type="text"
                                        name="clearing-nummer"
                                        id="clearing-nummer"
                                        autoComplete="clearing-nummer"
                                        onChange={(e) => {
                                            setClearingNumber(e.target.value);
                                        }}
                                        value={clearingNumber}
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                                    />
                                    {clearingNumberError && (
                                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                            {clearingNumberError}{" "}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Adresse
                                    </label>
                                    <input
                                        type="text"
                                        name="adresse"
                                        id="adresse"
                                        autoComplete="adresse"
                                        onChange={(e) => {
                                            setBankAddress(e.target.value);
                                        }}
                                        value={bankAddress}
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                                    />
                                    {bankAddressError && (
                                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                            {bankAddressError}{" "}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        IBAN Nummer
                                    </label>
                                    <input
                                        type="text"
                                        name="iban-nummer"
                                        id="iban-nummer"
                                        autoComplete="iban-nummer"
                                        onChange={(e) => {
                                            setIban(e.target.value);
                                        }}
                                        value={iban}
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                                    />
                                    {ibanError && (
                                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                            {ibanError}{" "}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Kontonummer
                                    </label>
                                    <input
                                        type="text"
                                        name="konto-nummer"
                                        id="konto-nummer"
                                        onChange={(e) => {
                                            setAccNumber(e.target.value);
                                        }}
                                        value={accNumber}
                                        autoComplete="konto-nummer"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                                    />
                                    {accNumberError && (
                                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                            {accNumberError}{" "}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Privacy section */}
                        <div className="pt-6 divide-y divide-gray-200">
                            <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
                                {/* <button
                      type="button"
                      className="bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                    >
                      Cancel
                    </button> */}
                                {edit == false ? (
                                    <button
                                        type="submit"
                                        className="ml-5 bg-sky-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                                        onClick={createBankDetails}
                                    >
                                        {t("settings.buttons.save")}
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        className="ml-5 bg-sky-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                                        onClick={updateBankDetails}
                                    >
                                        Update
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
