import React from 'react'

export default function Affiliate() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="py-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500 mb-2">Affiliate</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 w-full">
        Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
        qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
        pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
        </dd>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 w-full">
        Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
        qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
        pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
        </dd>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 w-full">
        Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
        qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
        pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
        </dd>
    </div>
</div>
  )
}
