import React, { useState } from 'react';
import Input from '../components/form/Input'
import Button from '../components/form/Button'

import axios from 'axios';
import { useTranslation } from "react-i18next";

import logo from "../assets/images/logo.jpeg";

export default function ResetPassword() {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  function sendEmail(e){
    e.preventDefault();

    if(email == ""){
      setEmailError("Email is required");
    }
   
    else{
      const data = {
        "email": email        
      }

      axios
      .post(window.REACT_APP_API_ENDPOINT+"/api/forgot/password", data)
      .then((response) => {
        console.log(response);
  
        if(response.status == 200){
          // const token = response.data.token;
          // localStorage.setItem('hotelgramer_token', token);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  }
  }

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                  <img
                  className="mx-auto h-12 w-auto"
                  src={logo}
                  alt="hotelgramer"
                  />
                  <h2 className="mt-6 text-left text-2xl font-extrabold text-gray-900">{t('form.reset_password.title')}</h2>
                  <p className="mt-2 mb-2 text-left text-sm text-gray-600">
                      {t('form.reset_password.description')}
                  </p>
                  <Input labelText={t('form.fields.email')} type="email" handleInputChange={(value)=>setEmail(value)} errorMsg={emailError}/>
              </div>
              <div className="mt-4">
                  <Button text={t('form.reset_password.btn')} handleClick={sendEmail}/>
              </div>
          </div>
      </div>
  </div>
  )
}
