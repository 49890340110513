/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          sky: colors.sky,
          teal: colors.teal,
        },
      },
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { React, Fragment, useState, useEffect } from "react";
import { Disclosure, Menu, Switch, Transition } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/solid";
import {
    BellIcon,
    CogIcon,
    CreditCardIcon,
    KeyIcon,
    MenuIcon,
    UserCircleIcon,
    ViewGridAddIcon,
    XIcon,
    CurrencyEuroIcon,
} from "@heroicons/react/outline";
import { axiosInstance } from "../../utils/axios";
import { getUser, setUserSession } from "../../utils/storage";
import UserSettingsWrapper from "./UserSettingsWrapper";
import { useTranslation } from "react-i18next";
const userLogged = getUser();

const user = {
    name: "Debbie Lewis",
    handle: "deblewis",
    email: "debbielewis@example.com",
    imageUrl:
        "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80",
};
const navigation = [
    { name: "Dashboard", href: "#", current: true },
    { name: "Jobs", href: "#", current: false },
    { name: "Applicants", href: "#", current: false },
    { name: "Company", href: "#", current: false },
];
const subNavigation = [
    { name: "Profile", href: "#", icon: UserCircleIcon, current: true },
    { name: "Account", href: "#", icon: CogIcon, current: false },
    { name: "Password", href: "#", icon: KeyIcon, current: false },
    { name: "Notifications", href: "#", icon: BellIcon, current: false },
    { name: "Billing", href: "#", icon: CreditCardIcon, current: false },
    { name: "Integrations", href: "#", icon: ViewGridAddIcon, current: false },
];
const userNavigation = [
    { name: "Your Profile", href: "#" },
    { name: "Settings", href: "#" },
    { name: "Sign out", href: "#" },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function UserProfileSettings() {
    const [availableToHire, setAvailableToHire] = useState(true);
    const [privateAccount, setPrivateAccount] = useState(false);
    const [allowCommenting, setAllowCommenting] = useState(true);
    const [allowMentions, setAllowMentions] = useState(true);

    const [name, setName] = useState(
        userLogged.name ? userLogged.name : ""
    );
  
    const [email, setEmail] = useState(
        userLogged.email ? userLogged.email : ""
    );
    const [bio, setBio] = useState(userLogged.bio ? userLogged.bio : "");
    const [username, setUsername] = useState(
        userLogged.username ? userLogged.username : ""
    );
    const [selectedFile, setSelectedFile] = useState("");

    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [usernameError, setUsernameError] = useState("");

    const { t, i18n } = useTranslation();

    function handleNameChange(e) {
        setName(e.target.value.replace(/[0-9]/, ""));
    }



    function handleEmailChange(e) {
        setEmail(e.target.value);
    }

    function handleBioChange(e) {
        setBio(e.target.value);
    }

    function handleUsernameChange(e) {
        setUsername(e.target.value);
    }

    function handleFileSelect(e) {
        setSelectedFile(e.target.files[0]);
    }

    // useEffect(() => {
    //   console.log("selected file", userLogged.file);
    // });

    function onUserUpdate(e) {
        e.preventDefault();
        // const data = {
        //     "name": firstName,
        //     "last_name": lastName,
        //     "email": email,
        //     "username": username,
        //     "bio": bio,
        //     'file': selectedFile
        //   }

        // console.log("data",data);
        let formData = new FormData();
        // formData.append('_method', 'patch')
        formData.append("name", name);
        formData.append("email", email);
        formData.append("username", username);
        formData.append("bio", bio);
        formData.append("file", selectedFile);


        // const config = {
        //     headers: { 'Content-Type': 'application/json' }
        //     // headers: { 'Content-Type': 'multipart/form-data' }
        // }

        //   // console.log("data", data);
        //   for (var [key, value] of data.entries()) {
        //     console.log(key, value);
        // }

        axiosInstance
            .post(
                window.REACT_APP_API_ENDPOINT +
                    "/api/users/" +
                    userLogged.id +
                    "/update",
                formData
            )
            // axios({
            //   method: "put",
            //   url: "http://127.0.0.1:8000/api/users/"+userLogged.id,
            //   data: data,
            // })
            .then((response) => {
                console.log(response);

                if (response.data == "This email is taken!") {
                    setUsernameError("Username is taken");
                    return;
                }

                if (response.data == "This username is taken!") {
                    setEmailError("Email is taken");
                    return;
                }

                setUserSession(
                    localStorage.getItem("hotelgramer_token"),
                    response.data.user
                );

                window.location.assign("/" + i18n.language + "/feed");
                if (response.status == 200) {
                    //   const token = response.data.access_token;
                    //   localStorage.setItem('hotelgramer_token', token);
                    //   navigate('/verify');
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response);
                    console.log("server responded");
                } else if (error.request) {
                    console.log("network error");
                } else {
                    console.log(error);
                }
            });
    }

    return (
        <UserSettingsWrapper type="profile">
            <form className="divide-y divide-gray-200 lg:col-span-9">
                {/* Profile section */}
                <div className="py-6 px-4 sm:p-6 lg:pb-8">
                    <div>
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            {t("settings.profile.title")}
                        </h2>
                        <p className="mt-1 text-sm text-gray-500">
                            {t("settings.profile.description")}
                        </p>
                    </div>

                    <div className="mt-6 flex flex-col lg:flex-row">
                        <div className="flex-grow space-y-6">
                            <div>
                                <label
                                    htmlFor="username"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    {t("settings.profile.username")}
                                </label>
                                <div className="mt-1 rounded-md shadow-sm flex">
                                    <span className="bg-gray-50 border border-r-0 border-gray-300 rounded-l-md px-3 inline-flex items-center text-gray-500 sm:text-sm">
                                        hotelgramer.com/
                                    </span>
                                    <input
                                        type="text"
                                        name="username"
                                        id="username"
                                        autoComplete="username"
                                        className="focus:ring-sky-500 focus:border-sky-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                        value={username}
                                        onChange={handleUsernameChange}
                                    />
                                    {nameError && (
                                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                            {nameError}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div>
                                <label
                                    htmlFor="about"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    {t("settings.profile.about")}
                                </label>
                                <div className="mt-1">
                                    <textarea
                                        id="about"
                                        name="about"
                                        rows={3}
                                        className="shadow-sm focus:ring-sky-500 focus:border-sky-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                                        onChange={handleBioChange}
                                        value={bio}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 flex-grow lg:mt-0 lg:ml-6 lg:flex-grow-0 lg:flex-shrink-0">
                            <p
                                className="text-sm font-medium text-gray-700"
                                aria-hidden="true"
                            >
                                Photo
                            </p>
                            <div className="mt-1 lg:hidden">
                                <div className="flex items-center">
                                    <div
                                        className="flex-shrink-0 inline-block rounded-full overflow-hidden h-12 w-12"
                                        aria-hidden="true"
                                    >
                                        <img
                                            className="rounded-full h-full w-full"
                                            src={
                                                selectedFile
                                                    ? URL.createObjectURL(
                                                          selectedFile
                                                      )
                                                    : userLogged.file
                                                    ? window.REACT_APP_API_ENDPOINT +
                                                      "" +
                                                      userLogged.file
                                                    : "https://imgur.com/E0h3MGt.png"
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <div className="ml-5 rounded-md shadow-sm">
                                        <div className="group relative border border-gray-300 rounded-md py-2 px-3 flex items-center justify-center hover:bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-sky-500">
                                            <label
                                                htmlFor="mobile-user-photo"
                                                className="relative text-sm leading-4 font-medium text-gray-700 pointer-events-none"
                                            >
                                                <span>Change</span>
                                                <span className="sr-only">
                                                    {" "}
                                                    user photo
                                                </span>
                                            </label>
                                            <input
                                                id="mobile-user-photo"
                                                name="user-photo"
                                                type="file"
                                                className="absolute w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                                                onChange={handleFileSelect}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="hidden relative rounded-full overflow-hidden lg:block">
                                <img
                                    className="relative rounded-full w-40 h-40"
                                    src={
                                        selectedFile
                                            ? URL.createObjectURL(selectedFile)
                                            : userLogged.file
                                            ? window.REACT_APP_API_ENDPOINT +
                                              "" +
                                              userLogged.file
                                            : "https://imgur.com/E0h3MGt.png"
                                    }
                                    alt=""
                                />
                                <label
                                    htmlFor="desktop-user-photo"
                                    className="absolute inset-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100"
                                >
                                    <span>Change</span>
                                    <span className="sr-only"> user photo</span>
                                    <input
                                        type="file"
                                        id="desktop-user-photo"
                                        name="user-photo"
                                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                                        onChange={handleFileSelect}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 grid grid-cols-12 gap-6">
                        <div className="col-span-12 sm:col-span-6">
                            <label
                                htmlFor="first-name"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t("settings.profile.full_name")}
                            </label>
                            <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                autoComplete="given-name"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                                onChange={handleNameChange}
                                value={name}
                            />
                            {nameError && (
                                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                    {nameError}
                                </span>
                            )}
                        </div>

                        <div className="col-span-12">
                            <label
                                htmlFor="url"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t("settings.profile.email")}
                            </label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                                onChange={handleEmailChange}
                                value={email}
                            />
                            {emailError && (
                                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                    {emailError}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                {/* Privacy section */}
                <div className="pt-6 divide-y divide-gray-200">
                    {/* <div className="px-4 sm:px-6">
                    <div>
                      <h2 className="text-lg leading-6 font-medium text-gray-900">Privacy</h2>
                      <p className="mt-1 text-sm text-gray-500">
                        Ornare eu a volutpat eget vulputate. Fringilla commodo amet.
                      </p>
                    </div>
                    <ul role="list" className="mt-2 divide-y divide-gray-200">
                      
                      <Switch.Group as="li" className="py-4 flex items-center justify-between">
                        <div className="flex flex-col">
                          <Switch.Label as="p" className="text-sm font-medium text-gray-900" passive>
                            Make account private
                          </Switch.Label>
                          <Switch.Description className="text-sm text-gray-500">
                            Pharetra morbi dui mi mattis tellus sollicitudin cursus pharetra.
                          </Switch.Description>
                        </div>
                        <Switch
                          checked={privateAccount}
                          onChange={setPrivateAccount}
                          className={classNames(
                            privateAccount ? 'bg-teal-500' : 'bg-gray-200',
                            'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              privateAccount ? 'translate-x-5' : 'translate-x-0',
                              'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            )}
                          />
                        </Switch>
                      </Switch.Group>
                      <Switch.Group as="li" className="py-4 flex items-center justify-between">
                        <div className="flex flex-col">
                          <Switch.Label as="p" className="text-sm font-medium text-gray-900" passive>
                            Allow commenting
                          </Switch.Label>
                          <Switch.Description className="text-sm text-gray-500">
                            Integer amet, nunc hendrerit adipiscing nam. Elementum ame
                          </Switch.Description>
                        </div>
                        <Switch
                          checked={allowCommenting}
                          onChange={setAllowCommenting}
                          className={classNames(
                            allowCommenting ? 'bg-teal-500' : 'bg-gray-200',
                            'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              allowCommenting ? 'translate-x-5' : 'translate-x-0',
                              'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            )}
                          />
                        </Switch>
                      </Switch.Group>
                      <Switch.Group as="li" className="py-4 flex items-center justify-between">
                        <div className="flex flex-col">
                          <Switch.Label as="p" className="text-sm font-medium text-gray-900" passive>
                            Allow mentions
                          </Switch.Label>
                          <Switch.Description className="text-sm text-gray-500">
                            Adipiscing est venenatis enim molestie commodo eu gravid
                          </Switch.Description>
                        </div>
                        <Switch
                          checked={allowMentions}
                          onChange={setAllowMentions}
                          className={classNames(
                            allowMentions ? 'bg-teal-500' : 'bg-gray-200',
                            'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              allowMentions ? 'translate-x-5' : 'translate-x-0',
                              'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            )}
                          />
                        </Switch>
                      </Switch.Group>
                    </ul>
                  </div>  */}
                    <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
                        {/* <button
                      type="button"
                      className="bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                    >
                      Cancel
                    </button> */}
                        <button
                            type="submit"
                            className="ml-5 bg-sky-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                            onClick={onUserUpdate}
                        >
                            {t("settings.buttons.save")}
                        </button>
                    </div>
                </div>
            </form>
        </UserSettingsWrapper>
    );
}
