import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { getUser } from '../utils/storage';

export const RequireAuth = ({ children }) => {
  const location = useLocation()
  // const auth = useAuth()
  const user = getUser();
  // const { i18n } = useTranslation();
  if (!user) {
    return <Navigate to={'/en/login'} state={{ path: location.pathname }} />
  }
  return children
}