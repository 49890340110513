import axios from "axios";
import { initializeApp } from "firebase/app";
import { OAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import googlelogin from "../assets/images/google-login.png";
import Button from "../components/form/Button";
import { useAuth } from "../utils/auth";
import { setUserSession } from "../utils/storage";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

export default function SignUp() {
    const auth = useAuth();
    const { t, i18n } = useTranslation();

    const [fullName, setFullName] = useState("");
    const [lastName, setLastName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [fullNameError, setFullNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const clientID1 = '1017740600671-2e25525fic1fgsaduj6facitha3f73oc.apps.googleusercontent.com';
    const redirectURI1 = 'https://travelgram.app/auth/google';
    const scope1 = 'profile email'; // You can add more scopes separated by spaces
    // Generate a random state value

    const googleSignInURL = `https://accounts.google.com/o/oauth2/v2/auth?` +
        `client_id=${clientID1}&` +
        `redirect_uri=${encodeURIComponent(redirectURI1)}&` +
        `scope=${encodeURIComponent(scope1)}&` +
        //  `state=${encodeURIComponent(state)}&` +
        `response_type=code`;


    const handleGoogleSignIn = () => {
        window.location.href = googleSignInURL;
    };

    const handleAppleSignIn = () => {
        const provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        // const firebaseConfig = {
        //     apiKey: "AIzaSyBIx5If2GRx63544x00h8mCEXlb415ZH68",
        //     authDomain: "travelgram-3fbfd.firebaseapp.com",
        //     projectId: "travelgram-3fbfd",
        //     storageBucket: "travelgram-3fbfd.appspot.com",
        //     messagingSenderId: "87045311047",
        //     appId: "1:87045311047:web:e3201a158eeee80716bf62",
        //     measurementId: "G-Z7SMWHJRNE"
        // };
        const firebaseConfig = {
            apiKey: "AIzaSyAWvvWxq1iey2GNRg4qZYqnEOUTcy_JBKk",
            authDomain: "travelgram-a7250.firebaseapp.com",
            projectId: "travelgram-a7250",
            storageBucket: "travelgram-a7250.appspot.com",
            messagingSenderId: "887715173660",
            appId: "1:887715173660:web:8f0fd2c54e7c03fa0e56d9",
            measurementId: "G-18XZ4NC4VB"
          };
        const app = initializeApp(firebaseConfig);
        const authApple = getAuth(app);
        signInWithPopup(authApple, provider)
            .then((result) => {
                console.log(result);
                sendUserDetailsToBackend(result);
            })
            .catch((error) => {
                console.log("Some thing went wrong Please Try again after some time.")
            });
    };
    const showErrorToast = (message) => {
        Swal.fire({
            title: 'Error!',
            text: message,
            icon: 'error',
            confirmButtonText: 'OK',
        });
    }
    const sendUserDetailsToBackend = async (result) => {
        try {
            const response = await axios.post(`${window.REACT_APP_API_ENDPOINT}/api/register-or-login-apple`, {
                id: result.user.uid,
                email: result.user.email,
                displayName: result.user.displayName,
            });
            const token = response.data.token;
            setUserSession(token, response.data.user);
            auth.login(response.data.user);
            window.location.assign(
                "/" + i18n.language + "/feed?recommendations=true"
            );
        } catch (error) {
            console.log("Some thing went wrong Please Try again after some time.")
        }
    };
    function createUser(e) {
        e.preventDefault();

        if (fullName == "") {
            setFullNameError("First Name is required");
        }
        if (lastName == "") {
            setLastNameError("Last Name is required");
        }
        if (username == "") {
            setUsernameError("Username is required");
        }
        if (email == "") {
            setEmailError("Email is required");
        }
        if (password == "") {
            setPasswordError("Password is required");
        }
        if (confirmPassword == "") {
            setConfirmPasswordError("Please, confirm your password");
        } else if (password != confirmPassword) {
            setConfirmPasswordError(
                "Your password and confirmation password do not match"
            );
        } else {
            const data = {
                name: fullName,
                username: username,
                email: email,
                password: password,
            };

            axios
                .post(window.REACT_APP_API_ENDPOINT + "/api/register", data)
                .then((response) => {
                    console.log(response);

                    if (response.status == 200) {
                        if (response.data == "This email is taken!") {
                            setEmailError("Email is taken");
                            return;
                        } else {
                            const token = response.data.access_token;
                            setUserSession(token, "");

                            // window.location.assign("/verify");
                            window.location.assign("/" + i18n.language + "/login");

                        }
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response);
                        console.log("server responded");
                    } else if (error.request) {
                        console.log("network error");
                    } else {
                        console.log(error);
                    }
                });
        }
    }

    return (
        <>
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        {t("form.sign_up.title")}
                    </h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Or{" "}
                        <a
                            href="/en/login"
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                            {t("form.sign_up.existing_acc")}
                        </a>
                    </p>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 text-left">
                                    {t("form.fields.full_name")}
                                </label>
                                <div className="mt-1">
                                    <input
                                        value={fullName}
                                        onChange={(e) =>
                                            setFullName(
                                                e.target.value.replace(/[0-9]/, "")
                                            )
                                        }
                                        type={"text"}
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    {fullNameError && (
                                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                            {fullNameError}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 text-left">
                                    {t("form.fields.username")}
                                </label>
                                <div className="mt-1">
                                    <input
                                        value={username}
                                        onChange={(e) =>
                                            setUsername(
                                                e.target.value
                                            )
                                        }
                                        type={"text"}
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    {usernameError && (
                                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                            {usernameError}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 text-left">
                                    {t("form.fields.email")}
                                </label>
                                <div className="mt-1">
                                    <input
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        type={"email"}
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    {emailError && (
                                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                            {emailError}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 text-left">
                                    {t("form.fields.pass")}
                                </label>
                                <div className="mt-1">
                                    <input
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        type={"password"}
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    {passwordError && (
                                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                            {passwordError}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 text-left">
                                    {t("form.fields.confirm_pass")}
                                </label>
                                <div className="mt-1">
                                    <input
                                        value={confirmPassword}
                                        onChange={(e) =>
                                            setConfirmPassword(e.target.value)
                                        }
                                        type={"password"}
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    {confirmPasswordError && (
                                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                            {confirmPasswordError}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div>
                                <Button
                                    text={t("form.sign_up.title")}
                                    handleClick={createUser}
                                />
                            </div>
                        </form>
                        <div className="center mt-3">
                            <button onClick={handleAppleSignIn} className="apple-btn">
                                <i className="fa fa-apple px-2"></i>
                                Sign Up with Apple
                            </button>
                            <button onClick={handleGoogleSignIn} className="mt-3">
                                <img
                                    className="block w-auto h-15 center"
                                    src={googlelogin}
                                    alt="hotelgramer"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
