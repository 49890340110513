import { Suspense } from 'react';
import './App.css';
import Footer from './components/footer/Footer';
import Navbar from './components/header/Navbar';
import InstagramPost from './components/post/InstagramPost';
import UserProfile from './components/user/UserProfile';
import UserProfileSettings from './components/user/UserProfileSettings';
import UserSettingsPass from './components/user/UserSettingsPass';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import LogIn from './pages/LogIn';
import PostForm from './pages/PostForm';
import QueryParamHandler from './pages/QueryParamHandler';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordForm from './pages/ResetPasswordForm';
import SignUp from './pages/SignUp';
import About from './pages/company/About';
import PrivacyPolicy from './pages/legal/PrivacyPolicy';
import TermsOfUse from './pages/legal/TermsOfUse';
import Contact from './pages/support/Contact';
import Affiliate from './pages/workwithus/Affiliate';
import Influencer from './pages/workwithus/Influencer';
import { IfAuth } from './utils/IfAuth';
import { RequireAuth } from './utils/RequireAuth';
import { AuthProvider } from './utils/auth';

import GoogleMapApi from './components/maps/GoogleMapApi';

import { useTranslation } from 'react-i18next';

import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import Analytics from './pages/Analytics';
import PageNotFound from './pages/PageNotFound';

function App() {
  const { i18n } = useTranslation();
  let lang = i18n.language == undefined ? "en" : i18n.language;
  return (
    <div className="App">
      <AuthProvider>
        <Suspense fallback={"Loading..."}>
          <Navbar />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path={"/" + lang + "/maps"} element={<GoogleMapApi />} />
              <Route path={"/" + lang + "/insta"} element={<InstagramPost />} />
              <Route path={"/" + lang + "/feed"} element={<RequireAuth><Dashboard /></RequireAuth>} />
              <Route path={"/" + lang + "/analytics"} element={<RequireAuth><Analytics /></RequireAuth>} />
              <Route path={"/" + lang + "/post-form"} element={<RequireAuth><PostForm /></RequireAuth>} />
              <Route path={"/" + lang + "/post-form/:id"} element={<RequireAuth><PostForm /></RequireAuth>} />
              <Route path={"/" + lang + "/profile/:username"} element={<RequireAuth><UserProfile /></RequireAuth>} />
              <Route path={"/" + lang + "/profile/:username/archived"} element={<RequireAuth><UserProfile /></RequireAuth>} />
              <Route path={"/" + lang + "/profile/:username/recommendations"} element={<RequireAuth><UserProfile /></RequireAuth>} />
              {/* <Route path={"/"+lang+"/settings"} element={<RequireAuth><UserSettingsDashboard /></RequireAuth>} /> */}
              {/* <Route path={"/"+lang+"/settings/bank"} element={<RequireAuth><UserProfileBankDetails /></RequireAuth>} /> */}
              <Route path={"/" + lang + "/settings/profile"} element={<RequireAuth><UserProfileSettings /></RequireAuth>} />
              <Route path={"/" + lang + "/settings/password"} element={<RequireAuth><UserSettingsPass /></RequireAuth>} />
              {/* <Route path={"/"+lang+"/settings/deactivate"} element={<RequireAuth><UserProfileDeactivate /></RequireAuth>} /> */}
              <Route path={"/" + lang + "/login"} element={<IfAuth><LogIn /></IfAuth>} />
              <Route path={"/" + lang + "/sign-up"} element={<IfAuth><SignUp /></IfAuth>} />
              <Route path={"/" + lang + "/reset-password"} element={<ResetPassword />} />
              <Route path={"/auth/google"} element={<QueryParamHandler />} />
              {/* <Route path={"/verify"} element={<VerifyEmail />} /> */}
              <Route path={"/" + lang + "/forgot/reset/:token"} element={<RequireAuth><ResetPasswordForm /></RequireAuth>} />
              <Route path={"/" + lang + "/legal/terms-of-use"} element={<TermsOfUse />} />
              <Route path={"/" + lang + "/legal/privacy-policy"} element={<PrivacyPolicy />} />
              <Route path={"/" + lang + "/company/about"} element={<About />} />
              <Route path={"/" + lang + "/support/contact"} element={<Contact />} />
              <Route path={"/" + lang + "/work-with-us/influencer"} element={<Influencer />} />
              <Route path={"/" + lang + "/work-with-us/affiliate"} element={<Affiliate />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
          <Footer />
        </Suspense>
      </AuthProvider>
    </div>
  );
}

export default App;
