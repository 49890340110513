/* This example requires Tailwind CSS v2.0+ */
import React, {useState, useEffect, Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { PlusSmIcon } from '@heroicons/react/solid'
import { eraseCookie, getUser } from '../../utils/storage'

import { useAuth } from '../../utils/auth'

import logo from "../../assets/images/logo.jpeg";

import { useTranslation } from 'react-i18next';

const userLogged = getUser();
// i18n.language
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
  // const [lang, setLang] = useState('');
  const { i18n, t } = useTranslation();
  const auth = useAuth();
  
  function logOut(){
    // localStorage.removeItem('hotelgramer_token');
    // auth.logout();
    localStorage.removeItem('user'); 
    localStorage.removeItem('hotelgramer_token'); 
    window.location.assign("/");
    eraseCookie("token");
 }

 function handleLangChange(e){
    // setLang(e.target.value);
    i18n.changeLanguage(e.target.value);
    // window.location.reload(false);
    // window.location.reload();
    if(window.location.pathname.substring(1).split('/')[0] == "en" || window.location.pathname.substring(1).split('/')[0] == "de"){


      let theURL = window.location.pathname;
      let newURL = theURL.replace(window.location.pathname.substring(1).split('/')[0], e.target.value);
      window.location.href = newURL;
    }

 }

  return (
    <div>
    {/* <div class="bg-white"><div class="
          max-w-7xl
          mx-auto
          h-10
          px-4
          flex
          items-center
          justify-between
          sm:px-6
          lg:px-8
        "><form class="lg:block lg:flex-1"><div class="flex"><label for="desktop-currency" class="sr-only">
      Sprache
    </label> <div class="
        -ml-2
        group
        relative
        bg-gray-900
        border-transparent
        rounded-md
        focus-within:ring-2 focus-within:ring-white
      "><select onChange={handleLangChange} value={i18n.language} id="desktop-currency" name="currency" className="
      bg-none bg-primaryColor
      border-transparent
      rounded-md
      py-0.5
      pl-2
      pr-5
      flex
      items-center
      text-sm
      font-medium
      text-white
      
      focus:outline-none focus:ring-0 focus:border-transparent
    "><option value="en">
      EN
    </option><option value="de">
      DE
    </option></select>  <div class="
          absolute
          right-0
          inset-y-0
          flex
          items-center
          pointer-events-none
        "><svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" class="w-5 h-5 text-gray-300"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M6 8l4 4 4-4"></path></svg></div></div></div></form> <div class="flex flex-1 items-center justify-end space-x-6"> <a href={"/"+i18n.language+"/sign-up"} class="text-sm font-medium text-primaryColor">
            Register
          </a> <span aria-hidden="true" class="h-6 w-px bg-primaryColor"></span> <a href={"/"+i18n.language+"/login"}  class="text-sm font-medium text-primaryColor">
            Log In
          </a></div></div></div> */}
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <a className="flex-shrink-0 flex items-center w-36" href={"/"}>
                  <img
                    className="block lg:hidden w-auto"
                    src={logo}
                    alt="hotelgramer"
                  />
                  <img
                    className="hidden lg:block w-auto"
                    src={logo}
                    alt="hotelgramer"
                  />
                </a>
                <div className="hidden md:ml-6 md:flex md:space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                 
                  <a
                    href={"/"}
                    className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                  >
                    {t('header.home')}
                  </a>
                  {userLogged && <a
                    href={"/"+i18n.language+"/analytics"}
                    className="border-transparent text-gray-500 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                  >
                    {t('header.community')}
                  </a>}

                  {userLogged && <a
                    href={"/"+i18n.language+"/feed?recommendations=true"}
                    className="border-transparent text-gray-500 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                  >
                    Community Feed
                  </a>}
                </div>
              </div>
              
              {!userLogged && <><div class="flex flex-1 items-center justify-end space-x-6"> <a href={"/"+i18n.language+"/sign-up"} class="text-sm font-medium text-primaryColor">
              {t('header.register')}
          </a> <span aria-hidden="true" class="h-6 w-px bg-primaryColor"></span> <a href={"/"+i18n.language+"/login"}  class="text-sm font-medium text-primaryColor">
          {t('header.login')}
          </a></div></>}
              {userLogged?<div className="flex items-center">

                 <div className="flex-shrink-0">
                  <a
                  href={"/"+i18n.language+"/post-form"}
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primaryColor shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <PlusSmIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    <span>{t('header.new_post')}</span>
                  </a>
                </div>
                <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                  {/* <button
                    type="button"
                    className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}
                  

                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full object-cover"
                          src={userLogged && userLogged.file? userLogged.file : 'https://imgur.com/E0h3MGt.png'}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href={"/"+i18n.language+"/profile/"+userLogged.username}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                               {t('header.dropdown.your_profile')}

                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                            href={"/"+i18n.language+"/settings/profile"}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                             {t('header.dropdown.settings')}

                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                              onClick={logOut}
                            >
                              {t('header.dropdown.sign_out')}

                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>: ''}
            </div> 
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              {userLogged && <Disclosure.Button
                as="a"
                href={"/"+i18n.language+"/analytics"}
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
              >
                {t('header.community')}
              </Disclosure.Button>}
              {userLogged && <Disclosure.Button
                as="a"
                href={"/"+i18n.language+"/feed?recommendations=true"}
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
              >
                Community Feed
              </Disclosure.Button>}
         
            
              <Disclosure.Button
                as="a"
                href="/"
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
              >
                {t('header.home')}
              </Disclosure.Button>
              {/* <Disclosure.Button
                as="a"
                href="#"
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
              >
                Projects
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="#"
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
              >
                Calendar
              </Disclosure.Button> */}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
            {userLogged && <div className="flex items-center px-4 sm:px-6">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={userLogged && userLogged.file? userLogged.file : 'https://imgur.com/E0h3MGt.png'}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">{userLogged.name} {userLogged.last_name}</div>
                  <div className="text-sm font-medium text-gray-500">{userLogged.email}</div>
                </div>
                {/* <button
                  type="button"
                  className="ml-auto flex-shrink-0 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}
              </div>}
              {userLogged && <div className="mt-3 space-y-1">
                <Disclosure.Button
                  as="a"
                  href={"/"+i18n.language+"/profile/"+userLogged.username}
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
                >
                  {t('header.dropdown.your_profile')}
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href={"/"+i18n.language+"/settings/profile"}
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
                >
                  {t('header.dropdown.settings')}

                </Disclosure.Button>
                <Disclosure.Button
                 onClick={logOut}
                  as="a"
                  href="#"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
                >
                  {t('header.dropdown.sign_out')}

                </Disclosure.Button>
              </div>}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
    </div>
  )
}