import axios from "axios";
import { initializeApp } from "firebase/app";
import { OAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import googlelogin2 from "../assets/images/google-login2.png";
import Button from "../components/form/Button";
import AppleUserModal from "../components/user/AppleUserModal";
import { useAuth } from "../utils/auth";
import { setUserSession } from "../utils/storage";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
export default function LogIn() {
    const auth = useAuth();

    const { t, i18n } = useTranslation();

    // const [user, setUser] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const [rememberMe, setRememberMe] = useState(false);

    const pattern = "/^[a-zA-Zs]*$/";

    useEffect(() => {
        const code = new URLSearchParams(location.search).get('code');
        console.log(code);

    }, [location]);

    const clientID1 = '1017740600671-2e25525fic1fgsaduj6facitha3f73oc.apps.googleusercontent.com';
    const redirectURI1 = 'https://travelgram.app/auth/google';
    const scope1 = 'profile email'; // You can add more scopes separated by spaces
    // Generate a random state value

    const googleSignInURL = `https://accounts.google.com/o/oauth2/v2/auth?` +
        `client_id=${clientID1}&` +
        `redirect_uri=${encodeURIComponent(redirectURI1)}&` +
        `scope=${encodeURIComponent(scope1)}&` +
        //  `state=${encodeURIComponent(state)}&` +
        `response_type=code`;

    const handleGoogleSignIn = () => {
        window.location.href = googleSignInURL;
    };

    const handleAppleSignIn = () => {
        const provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        // const firebaseConfig = {
        //     apiKey: "AIzaSyBIx5If2GRx63544x00h8mCEXlb415ZH68",
        //     authDomain: "travelgram-3fbfd.firebaseapp.com",
        //     projectId: "travelgram-3fbfd",
        //     storageBucket: "travelgram-3fbfd.appspot.com",
        //     messagingSenderId: "87045311047",
        //     appId: "1:87045311047:web:e3201a158eeee80716bf62",
        //     measurementId: "G-Z7SMWHJRNE"
        // };
        const firebaseConfig = {
            apiKey: "AIzaSyAWvvWxq1iey2GNRg4qZYqnEOUTcy_JBKk",
            authDomain: "travelgram-a7250.firebaseapp.com",
            projectId: "travelgram-a7250",
            storageBucket: "travelgram-a7250.appspot.com",
            messagingSenderId: "887715173660",
            appId: "1:887715173660:web:8f0fd2c54e7c03fa0e56d9",
            measurementId: "G-18XZ4NC4VB"
          };
        const app = initializeApp(firebaseConfig);
        const authApple = getAuth(app);
        signInWithPopup(authApple, provider)
            .then((result) => {
                console.log(result);
                sendUserDetailsToBackend(result);
            })
            .catch((error) => {
                console.log("Some thing went wrong Please Try again after some time.")
            });
    };
    const showErrorToast = (message) => {
        Swal.fire({
            title: 'Error!',
            text: message,
            icon: 'error',
            confirmButtonText: 'OK',
        });
    }
    const sendUserDetailsToBackend = async (result) => {
        try {
            const response = await axios.post(`${window.REACT_APP_API_ENDPOINT}/api/register-or-login-apple`, {
                id: result.user.uid,
                email: result.user.email,
                displayName: result.user.displayName,
            });
            const token = response.data.token;
            setUserSession(token, response.data.user);
            auth.login(response.data.user);
            window.location.assign(
                "/" + i18n.language + "/feed?recommendations=true"
            );
        } catch (error) {
            console.log("Some thing went wrong Please Try again after some time.")
        }
    };
    function userLogIn(e) {
        e.preventDefault();

        setEmailError("");
        setPasswordError("");

        if (email == "") {
            setEmailError("Email is required");
        }
        if (password == "") {
            setPasswordError("Password is required");
        } else {
            const data = {
                email: email,
                password: password,
                remember_me: rememberMe,
            };

            axios
                .post(window.REACT_APP_API_ENDPOINT + "/api/login", data)
                .then((response) => {
                    console.log(response);

                    if (Object.keys(response.data).length == 0) {
                        setEmailError("incorrect Email!");
                    } else if (response.status == 200) {

                        // if (response.data.user["email_verified_at"] !== null) {
                        const token = response.data.token;
                        setUserSession(token, response.data.user);

                        auth.login(response.data.user);


                        window.location.assign(
                            "/" + i18n.language + "/feed?recommendations=true"
                        );
                        // } else {
                        //     const token = response.data.token;
                        //     setUserSession(token, "");
                        //     window.location.assign("/verify");
                        // }
                    } else {
                        setPasswordError("incorrect Password!");
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response);
                        console.log("server responded");
                        if (error.response.status == 401) {
                            axios
                                .get(
                                    window.REACT_APP_API_ENDPOINT +
                                    "/api/users/" +
                                    email +
                                    "/email"
                                )
                                .then(function (response) {
                                    console.log(response.data);
                                    if (
                                        Object.keys(response.data).length == 0
                                    ) {
                                        setEmailError("incorrect Email!");
                                    } else {
                                        setPasswordError("incorrect Password!");
                                    }
                                });
                        }
                    } else if (error.request) {
                        console.log("network error");
                    } else {
                        console.log(error);
                    }
                });
        }
    }
    return (
        <>
            <div className="min-h-full flex">
                <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                                {t("form.log_in.title")}
                            </h2>
                            <p className="mt-2 text-sm text-gray-600">
                                Or{" "}
                                <a
                                    href={"/" + i18n.language + "/sign-up"}
                                    className="font-medium text-indigo-600 hover:text-indigo-500"
                                >
                                    {t("form.log_in.new_acc")}
                                </a>
                            </p>
                        </div>

                        <div className="mt-8">
                            <div className="mt-6">
                                <form className="space-y-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 text-left">
                                            Email
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                value={email}
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                                type={"email"}
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                            {emailError && (
                                                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                    {emailError}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="space-y-1">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 text-left">
                                                Password
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    value={password}
                                                    onChange={(e) =>
                                                        setPassword(e.target.value)
                                                    }
                                                    type={"password"}
                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                />
                                                {passwordError && (
                                                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                                        {passwordError}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between">
                                        <div className="text-sm">
                                            <a
                                                href="/en/reset-password"
                                                className="font-medium text-indigo-600 hover:text-indigo-500"
                                            >
                                                {t("form.log_in.pass_forgot")}
                                            </a>
                                        </div>
                                    </div>

                                    <div>
                                        <Button
                                            text={t("form.log_in.sign_in_btn")}
                                            handleClick={userLogIn}
                                        />
                                    </div>
                                </form>


                                <div className="center mt-3">
                                    <button onClick={handleAppleSignIn} className="apple-btn" style={{ padding: "21px" }}>
                                        <i className="fa fa-apple px-2 "></i>
                                        <span>Sign In with Apple</span>
                                    </button>
                                    <button onClick={handleGoogleSignIn} className="mt-3">
                                        <img
                                            className="block w-auto h-15 center"
                                            src={googlelogin2}
                                            alt="hotelgramer"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden lg:block relative w-0 flex-1">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                        alt=""
                    />
                </div>
            </div>
        </>
    );
}
