import React from 'react'
import { Fragment, useState } from 'react'
import { getUser, setUserSession } from '../../utils/storage';
import UserSettingsWrapper from './UserSettingsWrapper';
import { axiosInstance } from '../../utils/axios';
import { useTranslation } from "react-i18next";

const userLogged = getUser();

export default function UserSettingsPass() {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

    const { t } = useTranslation();

    function onPassUpdate(e){
        e.preventDefault();

        let data = {
            'password': newPassword
        }
        axiosInstance.put(window.REACT_APP_API_ENDPOINT+"/api/users/password", data)
        .then((response) => {
          console.log(response);
          if(response.status == 200){
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });
    }
  return (
    <UserSettingsWrapper type="password">
         <form className="divide-y divide-gray-200 lg:col-span-9">
                {/* Profile section */}
                <div className="py-6 px-4 sm:p-6 lg:pb-8">
                  {/* <div>
                    <h2 className="text-lg leading-6 font-medium text-gray-900">Profile</h2>
                    <p className="mt-1 text-sm text-gray-500">
                      This information will be displayed publicly so be careful what you share.
                    </p>
                  </div> */}

                 

                  <div className="mt-12 grid grid-cols-12">
                    <div className="col-span-12 sm:col-span-12 mb-5">
                      <label htmlFor="old-password" className="block text-sm font-medium text-gray-700">
                      {t('settings.password.old_password')}
                      </label>
                      <input
                        type="password"
                        name="old-password"
                        id="old-password"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                        onChange={(e)=>{setPassword(e.target.value)}}
                        value={password}
                      />
                    </div>

                    <div className="col-span-12 sm:col-span-12 mb-5">
                      <label htmlFor="new-password" className="block text-sm font-medium text-gray-700">
                      {t('settings.password.new_password')}
                      </label>
                      <input
                        type="password"
                        name="new-password"
                        id="new-password"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                        onChange={(e)=>{setNewPassword(e.target.value)}}
                        value={newPassword}
                      />
                    </div>

                    <div className="col-span-12 sm:col-span-12 mb-5">
                      <label htmlFor="confirm-new-password" className="block text-sm font-medium text-gray-700">
                      {t('settings.password.confirm_password')}
                      </label>
                      <input
                        type="password"
                        name="confirm-new-password"
                        id="confirm-new-password"
                        autoComplete="given-name"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                        onChange={(e)=>{setNewPasswordConfirm(e.target.value)}}
                        value={newPasswordConfirm}
                      />
                    </div>
                    
                  </div>
                </div>

                
                  <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
                    {/* <button
                      type="button"
                      className="bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                    >
                      Cancel
                    </button> */}
                    <button
                      type="submit"
                      className="ml-5 bg-sky-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                    onClick={onPassUpdate}
                   >
                     {t('settings.vuttons.save')}
                    </button>
                  </div>
                {/* </div> */}
              </form>
    </UserSettingsWrapper>
  )
}
