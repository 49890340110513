import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function HotelRecommendation(props) {
  return (
    <ul role="list" className="divide-y divide-gray-100 w-full max-w-7xl mx-auto mt-14">
      <h3 className='mb-4 font-bold text-lg text-gray-500'>Invite People and connect to them to see their Hotel Stays</h3>
      {props.hotels.map((hotel) => (
        <li key={hotel.id} className="flex items-top justify-between gap-x-6 py-5">
          <div className="min-w-0">
            <div className="flex items-start gap-x-3">
              <p className="text-sm font-semibold leading-6 text-gray-900">{hotel.username}</p>
             
            </div>
            <div className="mt-1 flex items-top gap-x-2 text-xs leading-5 text-gray-500">
              <p className="whitespace-wrap">
                {hotel.location}
              </p>
              
            </div>
          </div>
          <div className="flex flex-none items-top gap-x-4">
            <a
            href={"whatsapp://send?text=Hi! Join www.travelgram.app, where you can follow others and discover the hotels they've stayed in. It's the 1st Social Media App for booking Hotels."}      data-action="share/whatsapp/share"  
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block bg-gray-200"
            >
              Invite
            </a>
           
          </div>
        </li>
      ))}
    </ul>
  )
}