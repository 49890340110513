import React from 'react'
import { useTranslation } from 'react-i18next';
import firstTeamMember from '../../assets/images/team-member-1.png'
import secondTeamMember from '../../assets/images/team-member-2.png'


export default function About() {
  const { t } = useTranslation();

      return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
        {/* <div className="py-4 sm:py-5 mb-4">
          <h1 className="text-4xl font-bold mb-4 text-bold text-gray-500">{t('about.title')}</h1>
            <p className="text-lg text-gray-500">
            {t('about.description-1')}</p>
            <br/>
            <p className="text-lg text-gray-500">
              {t('about.description-2')}
            </p>
           
            <h3 className="text-xl font-bold text-gray-500 mb-2 mt-6">{t('about.our-vision-subtitle')}</h3>
            <h2 className="text-3xl font-bold text-gray-500 mb-2">{t('about.our-vision-title')}</h2>


            <p className="text-lg text-gray-500">{t('about.our-vision-description')} </p>
            <ul className="list-disc pl-10">
              <li className="mt-1 text-lg text-gray-500">{t('about.our-vision-item-1')}</li>
              <li className="mt-1 text-lg text-gray-500">{t('about.our-vision-item-2')}</li>
              <li className="mt-1 text-lg text-gray-500">{t('about.our-vision-item-3')}</li>
              <li className="mt-1 text-lg text-gray-500">{t('about.our-vision-item-4')}</li>
            </ul>

            <p className="text-lg text-gray-500">{t('about.our-vision-footer')} </p>
                
              </div>
              <ul
                role="list"
                className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-2 lg:max-w-5xl text-center"
              >
                  <li key={"christian"}>
                    <div className="space-y-6">
                      <img className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56" src={secondTeamMember} alt="" />
                      <div className="space-y-2">
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <h3>{"Christian Szklennik"}</h3>
                          <p className="text-gray-500">{"Product"}</p>
                        </div>
                        <ul role="list" className="flex justify-center space-x-5">
                          <li>
                            <a href={"#"} className="text-gray-400 hover:text-gray-500">
                              <span className="sr-only">LinkedIn</span>
                              <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                  fillRule="evenodd"
                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>

                  <li key={"Jörg Hönigmann"}>
                    <div className="space-y-6">
                      <img className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56" src={firstTeamMember} alt="" />
                      <div className="space-y-2">
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <h3>{"Jörg Hönigmann"}</h3>
                          <p className="text-gray-500">{"Marketing"}</p>
                        </div>
                        <ul role="list" className="flex justify-center space-x-5">
                          <li>
                            <a href={"#"} className="text-gray-400 hover:text-gray-500">
                              <span className="sr-only">LinkedIn</span>
                              <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                  fillRule="evenodd"
                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
              </ul> */}
            </div>
      )
    }
