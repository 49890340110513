import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { setUserSession } from "../utils/storage";
import { useTranslation } from "react-i18next";
import { useAuth } from "../utils/auth";

function QueryParamHandler() {
  const [userDetails, setUserDetails] = useState(null);
  const auth = useAuth();
  const { t, i18n } = useTranslation();

  useEffect(() => {

    // const currentUrl = window.location.href;
    const code = new URLSearchParams(location.search).get('code');
    if (code) {
      exchangeCodeForAccessToken(code);
    }
  }, [location]);

  const exchangeCodeForAccessToken = async (code) => {
    try {
      const response = await axios.get(`${window.REACT_APP_API_ENDPOINT}/api/auth/google?code=${code}`);
     
      const access_token = response.data.access_token;
      fetchUserDetails(access_token);
    } catch (error) {
      console.error('Error exchanging code for access token', error);
    }
  };

  const fetchUserDetails = async (token) => {
    try {
      const response = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setUserDetails(response.data);
      sendUserDetailsToBackend(response.data)
      // Send user details to your backend for storage if needed
    } catch (error) {
      console.error('Error fetching user details', error);
    }
  };

  const sendUserDetailsToBackend = async (userData) => {
    try {
      const response = await axios.post(`${window.REACT_APP_API_ENDPOINT}/api/register-or-login`, {
        google_id: userData.sub,
        name: userData.name,
        email: userData.email,
        image: userData.picture
      });
      console.log('User details stored in backend', response.data);
      const token = response.data.token;
     
      setUserSession(token, response.data.user);

      auth.login(response.data.user);
      window.location.assign(
        "/" + i18n.language + "/feed?recommendations=true"
      );
    } catch (error) {
      console.error('Error storing user details in backend', error);
    }
  };

  if (userDetails) {
    // Render user details if available
    return (
      <div>
      
      </div>
    );
  } else {
    // Show loading or error message
    return <div>Authenticating...</div>;
  }
}

export default QueryParamHandler;
